import { debounce, uniqueId } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import {  createOrUpdateBoqApi, fileUploadApi, getSiteBoqByIdApi, removeBoqItemApi, submitSiteBoqByIdApi } from '../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import {  boqAddInitalValue, boqAddValidationSchema,  } from '../../utils/Validation';
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import * as XLSX from 'xlsx-js-style';


function AddSiteBOQ() {
  const [addLoader, setAddLoader] = useState(false)
  const submitConfirmModal = "submitConfirmModal1"
  const saveAndUploadConfirmModal = "saveAndUploadConfirmModalId"
  const reUploadConfirmModal = "reUploadConfirmModalId"
  const statusModalId = "statusModal1"
  const convertConfirmModal = "convertConfirmModal1"
  const navigate = useNavigate();
  const params = useParams();
  const { boqId } = params;
  const [selectItemId, setSelectItemId] = useState();
  const { userDetails,langPreference} = useUserContext();
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [updateItem,setUpdateItem] = useState({index:null,loading:false})
  const location = useLocation();
  const [isUpload,setUpload] = useState(false)
  const fileRef = useRef()


  const handleSelectDate = (e, eleType) => {
    boqFormik.setFieldValue(eleType, new Date(e).toISOString())
  };


  const boqSubmit = async (values) => {
    if(values?._id){
      const payload = {
        _id:values._id,
        contractStartDate:values.contractStartDate,
        contractDate:values.contractDate,
        completionDate:values.completionDate,
        actualDate:values.actualDate,
        category:values?.category,
        incDesc:values?.incDesc,
      }
      try {
        setAddLoader(true)
        const res =  await submitSiteBoqByIdApi(payload);
        if (res.status === 200) {
          toast.success(res.data.message);
          document.getElementById(submitConfirmModal).click()
          setConfirmationMsg(res?.data?.message || `BOQ ${res.data?.data?.boqNumber || ""} is successfully submitted.`)
          document.getElementById('statusModalBtn').click()
        }
      } catch (error) {
        error && error.response ?
          toast.error(error.response?.data?.message) :
          toast.error("something went wrong");
      }
      setAddLoader(false)
    }
  }

  const boqFormik = useFormik({
    initialValues: boqAddInitalValue,
    onSubmit: boqSubmit,
    validationSchema: boqAddValidationSchema(langPreference),
  })

  const getSiteBoqById = async () => {
    const res = await getSiteBoqByIdApi(boqId)
    if (res.status === 200) {
      const data = res.data?.data
      if(data){
        const list = data?.items?.map(ele=>{
          return {
            ...ele,
            saved:true,
            isDraft:false,
            unitPrice:Number(ele?.unitPrice || 0)?.toFixed(2),
            amount:Number(ele?.amount || 0)?.toFixed(2)
          }
        })
        boqFormik.setValues(data)
        boqFormik.setFieldValue("items",list)
      }
    }
  }

  useEffect(() => {
    if(boqId){
      getSiteBoqById(boqId)
    }
  }, [boqId])

  const handleConfirmation = (isConvert) => {
    boqFormik.handleSubmit();
  }

  const checkValidation = (btn) => {
    const errors = Object.keys(boqFormik.errors)   
    if (errors?.length > 0) {
      boqFormik.handleSubmit()
    } else {
      document.getElementById(btn).click()
    }
  }

  const checkSaveValidation = (value,index) => {
    const error = boqFormik?.errors?.items?.filter((ele,ind)=>index==ind)?.[0]
    if(!error){
      handleBoqItemSave(value,index)
    }else{
      boqFormik.handleSubmit()
    } 
  }

  const handleApprovalModalClose = () => {
     navigate("/boq")
  }

const handleAddItemQty =(index, sNo)=>{
const isUnSaved = boqFormik?.values?.items?.length && boqFormik?.values?.items?.filter(ele=>!ele?.saved)?.length
if(isUnSaved){
  toast.error(langPreference?.save_to_continue || "Please save item")
  return
}

  const item = {
    nameAndDetails:"",
    saved:false,
    isDraft:true,
    quantity: '',
    unitPrice: '',
    unitType: "",
    amount:""
  }
 let list = []
  boqFormik?.values?.items?.forEach((ele,ind)=>{
    if(index==ind){
      list.push(ele,item)
    }else{
      list.push(ele)
    }
  })

  if(index=="add"){
    list.push(item)
  }
  boqFormik.setFieldValue("items", list)
}

const handleRemoveItemQty =async(value,index)=>{
  try {
    let newList = boqFormik.values.items.filter((item,ind)=>ind!=index)
    if(value?.isDraft){
      boqFormik?.setFieldValue("items",newList)
      return
    } 
    const res =  await removeBoqItemApi({_id:value?._id});
    if (res.status === 200) {
      toast.success(res.data.message);
      boqFormik?.setFieldValue("items",newList)
    }
    
  } catch (error) {
    console.log("handleRemoveItemQty error",error);
    error && error.response ?
      toast.error(error.response?.data?.message) :
      toast.error("something went wrong");
  }
}

 const handleBoqItemSave =async(values,index)=>{
  try {
    setUpdateItem({index,loading:true})
    const res =  await createOrUpdateBoqApi({...boqFormik?.values,items:[values]});
    if (res.status === 200) {
      toast.success(res.data.message);
      const data = res?.data?.data
      boqFormik?.setFieldValue(`grandTotal`,data?.grandTotal)
      boqFormik?.setFieldValue(`amount`,data?.amount)

     boqFormik?.setFieldValue(`items[${index}].saved`,true)
     boqFormik?.setFieldValue(`items[${index}].isDraft`,false)
     boqFormik?.setFieldValue(`items[${index}]._id`,data?.items?.[0]?._id)
     boqFormik?.setFieldValue(`items[${index}].uniqueBoqItemId`,data?.items?.[0]?.uniqueBoqItemId)

     boqFormik?.setFieldValue("_id",data?._id)
     boqFormik?.setFieldValue("boqNumber",data?.boqNumber)
    }
  } catch (error) {
    console.log("error",error);
    
    error && error.response ?
      toast.error(error.response?.data?.message) :
      toast.error("something went wrong");
  }
  setUpdateItem({index:null,loading:false})
 }


const handleChange =(e,index,item,type)=>{
  const {name,value} = e?.target
  if(isNaN(Number(value)) && type=="number"){
    return
  }
  
  boqFormik.handleChange(e)
  boqFormik.handleBlur(e)

  if(item && (name?.includes("unitPrice") || name?.includes("quantity"))){
    const {unitPrice,quantity} = item
    let amount = quantity && unitPrice ? Number(quantity)*Number(unitPrice) : 0
    boqFormik.setFieldValue(`items[${index}].amount`,Number(amount).toFixed(2))
  }

  if(item?.saved){
    boqFormik.setFieldValue(`items[${index}].saved`,false)
    boqFormik.setFieldValue(`items[${index}].isDraft`,true)
  }
}

const getTotalAmt =(items)=>{
  let total =0
  items?.forEach(ele=>{
    total+=Number(ele?.quantity)*Number(ele?.unitPrice)
  })
  return total
}


const handleUploadAndSave =async(file,data)=>{
  try {
    setUpload(true)
    const formData = new FormData();
    formData.append('file', file);
    formData.append("type", "boq")
    const res = await fileUploadApi(formData)
    if (res.status == 200 && res.data?.status === 1) {
      console.log("uploadFile",res?.data);
      let uploadFile = res?.data?.data
      
     const result =  await createOrUpdateBoqApi({...boqFormik?.values,items:data,uploadFile});
      if (result.status === 200) {
      toast.success(result.data.message);
      const data = result?.data?.data
      boqFormik.setFieldValue("_id",data?._id)
      document.getElementById("saveAndUploadConfirmModal").click()
    }
    setUpload(false)
  }
  } catch (error) {
    setUpload(false)
  }
}

const handleFileUpload = async (e) => {
  try {
    const file = e.target.files[0];
    const fileSplitArr = file?.name?.split(".")
    const fileExtension = fileSplitArr?.[fileSplitArr?.length-1]
    const allowedExtensions = ['xlsx','xls']

    if(!allowedExtensions?.includes(fileExtension)){
      toast.error(`Only xlsx, xls file allow`);
      return
    }
    
    if (file) {
      setUpload(true)
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
        // Validate the presence of required columns
        const requiredFields = ["SNo","Name and Details", "Unit", "Total Quantity", "Rate","Amount"];
        const headers = Object.keys(jsonData[0] || {});
        const missingFields = requiredFields.filter((field) => !headers.includes(field));
        if (missingFields.length > 0) {
          toast.error(`Missing required fields: ${missingFields.join(", ")}`);
          fileRef.current.value = null
          setUpload(false)
          return
        }
  
        if(jsonData?.length==0){
          fileRef.current.value = null
          setUpload(false)
          toast.error(`Data not found`);
        }
  
        // Extract relevant columns
        const filteredData = [];
        for (const [index, row] of jsonData.entries()) {
          const sNo = row["SNo"] || "";
          const nameAndDetails = row["Name and Details"] || "";
          const unitType = row["Unit"] || "";
          const quantity = row["Total Quantity"] || 0;
          const unitPrice = row["Rate"] || 0;
          const amount = row["Amount"] || 0;
  
          // Add valid row to filtered data
          filteredData.push({
            sNo,
            nameAndDetails,
            unitType,
            quantity,
            unitPrice,
            saved:true,
            isDraft:false,
            amount:Number(amount)?.toFixed(2)
          });
        }

        handleUploadAndSave(file,filteredData)
      };
      reader.readAsArrayBuffer(file);
    }

  } catch (error) {
    fileRef.current.value = null
    console.log("error",error);
    setUpload(false)
    toast.error("Failed to upload")
  }
}
console.log("bo",boqFormik);


  return (
    <>
      <div className="dash-nav1">
        <div className="align-items-center d-block d-lg-flex justify-content-between">
      <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference?.back || "Back"}</span></div>
          <h3 className="mb-0 font-blue">{boqId ? langPreference?.edit_contract_management || "Edit Contract Management" : langPreference?.add_contract_management || "Add Contract Management"} </h3>
          <div>            
            <button type="button" disabled={addLoader} className="mb-2 mb-sm-2 sbm-purchase" onClick={() => {
              if (boqFormik.values?.items.filter(item => item.saved)?.length) {
                if(boqFormik.values?.items.filter(item => item.isDraft)?.length){
                  toast.error(langPreference?.save_to_continue ||  "Please save items")
                  return
                }
                checkValidation("submitModalBtn1")
              } else toast.error(langPreference?.please_select_items || "Please select items")
            }}>
              {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.submit_for_approval || "Submit for approval"}
            </button>
          </div>
        </div>
      </div>
      <div className="dash-table">
        <div className="container">
          <div className="row gap-3 gap-xl-0">
            <div className="col-12">
              <div className="">
                <div className='d-flex justify-content-between align-items-center bb'>
                </div>
                <div>
                 <div className="form-details">
                      <div className="">
                        <div className='row row-cols-6 align-items-end'>
                        <div className="d-flex flex-column justify-content-between">
                          <label htmlFor="contractDate">{langPreference?.contract_date || "Contract Date"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Contract Date' className='form-select cursor-pointer' name='contractDate' selected={boqFormik.values?.contractDate} onChange={(e) => { handleSelectDate(e, "contractDate") }} />
                          <span id="contractDate"></span>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <label htmlFor="contractStartDate">{langPreference?.date_of_start_contract || "Date of Start (as per Contract)"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Contract Start Date' className='form-select cursor-pointer' name='contractStartDate' selected={boqFormik.values?.contractStartDate} onChange={(e) => { handleSelectDate(e, "contractStartDate") }} />
                          <span id="contractStartDate"></span>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <label htmlFor="completionDate">{langPreference?.date_of_completion_contract || "Date of Completion (as per contract)"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Completion Date' className='form-select cursor-pointer' name='completionDate' selected={boqFormik.values?.completionDate} onChange={(e) => { handleSelectDate(e, "completionDate") }} />
                          <span id="completionDate"></span>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <label htmlFor="actualDate">{langPreference?.date_of_start_actual || "Actual Date"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Actual Date' className='form-select cursor-pointer' name='actualDate' selected={boqFormik.values?.actualDate} onChange={(e) => { handleSelectDate(e, "actualDate") }} />
                          <span id="actualDate"></span>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                        <label htmlFor="category">{langPreference?.category || "Category"}</label>
                          <input
                            type="text"
                            className="form-control"
                            name={`category`}
                            value={boqFormik?.values?.category}
                            onChange={boqFormik?.handleChange}
                            onBlur={boqFormik?.handleBlur}
                            disabled={updateItem?.loading}
                            // style={{ height: "30px", width: "60px" }}
                          />
                          {boqFormik?.touched?.category && boqFormik?.errors?.category ? (<div className='req-error'>{boqFormik?.errors?.category}</div>) : null}
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                        <label htmlFor="incDesc">{langPreference?.increase_decrease || "Increase/Decrease"}</label>
                          <input
                            type="text"
                            className="form-control"
                            name={`incDesc`}
                            value={boqFormik?.values?.incDesc ?? ""} // Ensure a controlled component
                            onChange={(e)=>{
                              let val = e.target?.value
                              if(val?.split(".")[1]?.length < 3 || val?.split(".")[1]?.length === undefined && (val=="-" || !isNaN(val))){
                                boqFormik.setFieldValue("incDesc",val)
                              }}}
                            onBlur={boqFormik?.handleBlur}
                            disabled={updateItem?.loading}
                          />
                          {boqFormik?.touched?.incDesc && boqFormik?.errors?.incDesc ? (<div className='req-error'>{boqFormik?.errors?.incDesc}</div>) : null}
                        </div>
                        </div>
                      </div>
                      <div className='mt-3 d-flex justify-content-between'>
                       <div>{langPreference?.total_amount || "Total Amount"}: {!isNaN(boqFormik?.values?.amount) ? Number(boqFormik?.values?.amount)?.toFixed(2) : ""}</div> 
                       <div>{langPreference?.inc_dec_value || "Inc/Dec value"}: {(!isNaN(boqFormik?.values?.grandTotal) && !isNaN(boqFormik?.values?.amount)) ? Number(Number(boqFormik?.values?.grandTotal)-Number(boqFormik?.values?.amount))?.toFixed(2) : 0}</div> 
                       <div>{langPreference?.grand_total || "Grand Total"}: {!isNaN(boqFormik?.values?.grandTotal) ? Number(boqFormik?.values?.grandTotal)?.toFixed(2) : ""}</div> 
                       {boqFormik?.values?.boqNumber && <div>{langPreference?.boq_number || "BOQ Number"}: {boqFormik?.values?.boqNumber}</div> }

                      </div>
                    </div>
                </div>
                  <>
                      <div className='mb-3  border-0 m-0 p-0' >
                        {/* First Row: Basic Details */}
                        <div className='w-100 mt-1'>
                          <div className='d-flex justify-content-between'>
                         <h3 className="mb-0 font-blue">{langPreference?.bill_of_quantity || "Bill of Quantity (BOQ)"} </h3>
                        <button type="button"  className="add-vendor mb-sm-0 mb-sm-2" onClick={(e)=>{
                          if(!isUpload){
                            fileRef.current.value = null
                            if(boqFormik?.values?.items?.filter(ele=>ele?.saved)?.length){
                              document.getElementById("reUploadConfirmModal").click()
                            }else{
                              fileRef.current.click()

                            }
                          }
                        }} >
                        {isUpload ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.upload_and_save || "Upload and Save"}
                        </button>
                        <input type='file' accept=".xlsx, .xls" style={{display:'none'}} ref={fileRef} disabled={isUpload} onChange={(e) => {!isUpload && handleFileUpload(e)}}/>
                          </div>
                          <div className="">
                            <table className="table d-table mt-4" style={{position:'relative'}} >
                              <thead style={{position:'sticky', top: '75px', backgroundColor: '#fbfbfb'}}>
                                <tr>
                                <th>{langPreference?.serial_no || "S.No"}</th>
                                  <th>{langPreference?.item_name_details || "Name and Details"}</th>
                                    <th>{langPreference?.unit || "Unit"}</th>
                                   <th>{langPreference?.qty || "Qty"}</th>
                                    <th>{langPreference?.rate || "Rate"}</th>
                                    <th>{langPreference?.amount || "Amount"}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className=''>
                              {boqFormik?.values?.items?.map((ele, index) => (
                               <tr key={index} style={{height:"50px"}}>
                                   <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].sNo`}
                                        value={boqFormik?.values?.items?.[index]?.sNo}
                                        onChange={(e)=>handleChange(e,index,ele,"text")}
                                        onBlur={(e)=>handleChange(e,index,ele,"text")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px",width:"60px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.sNo && boqFormik?.errors?.items?.[index]?.sNo ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.sNo}</div>) : null}
                                    </td>
                                    <td >
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].nameAndDetails`}
                                        value={boqFormik?.values?.items?.[index]?.nameAndDetails}
                                        onChange={(e)=>handleChange(e,index,ele,"text")}
                                        onBlur={(e)=>handleChange(e,index,ele,"text")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.nameAndDetails && boqFormik?.errors?.items?.[index]?.nameAndDetails ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.nameAndDetails}</div>) : null}
                                     </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].unitType`}
                                        value={boqFormik?.values?.items?.[index]?.unitType}
                                        onChange={(e)=>handleChange(e,index,ele,"text")}
                                        onBlur={(e)=>handleChange(e,index,ele,"text")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px",width:"60px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.unitType && boqFormik?.errors?.items?.[index]?.unitType ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.unitType}</div>) : null}
                                    </td>
                                    <td >
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].quantity`}
                                        value={boqFormik?.values?.items?.[index]?.quantity}
                                        onChange={(e)=>(e.target?.value?.split(".")[1]?.length < 3 || e.target?.value?.split(".")[1]?.length === undefined) && handleChange(e,index,ele,"number")}
                                        onBlur={(e)=>handleChange(e,index,ele,"number")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px",width:"80px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.quantity && boqFormik?.errors?.items?.[index]?.quantity ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.quantity}</div>) : null}
                                     </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].unitPrice`}
                                        value={boqFormik?.values?.items?.[index]?.unitPrice}
                                        onChange={(e)=>(e.target?.value?.split(".")[1]?.length < 3 || e.target?.value?.split(".")[1]?.length === undefined) && handleChange(e,index,ele,"number")}
                                        onBlur={(e)=>handleChange(e,index,ele,"number")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px",width:"80px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.unitPrice && boqFormik?.errors?.items?.[index]?.unitPrice ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.unitPrice}</div>) : null}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].amount`}
                                        value={boqFormik?.values?.items?.[index]?.amount}
                                        onChange={(e)=>(e.target?.value?.split(".")[1]?.length < 3 || e.target?.value?.split(".")[1]?.length === undefined) && handleChange(e,index,ele,"number")}
                                        onBlur={(e)=>handleChange(e,index,ele,"number")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px",width:"150px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.amount && boqFormik?.errors?.items?.[index]?.amount ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.amount}</div>) : null}
                                    </td>
                                    <td>
                                      <div className='d-flex gap-2'>
                                      {<button className={`${ele?.saved ? "save-btn" : "unsave-btn"}`}  disabled={updateItem?.loading} onClick={()=>checkSaveValidation(ele,index)}>{updateItem?.index==index ? "Saving" :langPreference?.save || "Save"}</button>}
                                      {<button className='add-vendor'  disabled={updateItem?.loading}  onClick={()=>handleRemoveItemQty(ele,index)}>-</button>}
                                      <button  disabled={updateItem?.loading} className='add-vendor'  onClick={() => {  handleAddItemQty(index) }}>+</button>
                                      </div>
                                    </td>
                                
                                  </tr>))}

                              </tbody>
                            </table>
                            <div className='d-flex justify-content-end'>
                            <button  disabled={updateItem?.loading} className='add-vendor'  onClick={() => {  handleAddItemQty("add") }}>+</button>
                            </div>
                          </div>
                        </div>
                      </div>
                  </>

              </div>
            </div>

          </div>
        </div>
      </div>
      {/* for po approval */}
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={() => handleConfirmation(false)} message={langPreference?.submit_confirmation || <>Are you sure you want to <br /> Submit ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleApprovalModalClose}
      />
      <button className='d-none' id="saveAndUploadConfirmModal" data-bs-toggle="modal" data-bs-target={`#${saveAndUploadConfirmModal}`} />
      <StatusModal
        modalId={saveAndUploadConfirmModal}
        message={langPreference?.uploaded_success || 'Uploaded successfully, click on "Submit for approval"'}
        handleClose={()=>{
          if(boqId!=boqFormik?.values?._id){
            navigate(`/boq/update/${boqFormik?.values?._id}`)
          }else{
             window.location.reload()
          }
        }}
      />
      <button className='d-none' id="reUploadConfirmModal" data-bs-toggle="modal" data-bs-target={`#${reUploadConfirmModal}`} />
  <ConfirmationModal modalId={reUploadConfirmModal}  handleConfirm={()=>{fileRef.current.click()}}  message={langPreference?.delete_earlier_boq || 'Earlier BOQ will be deleted'} />
     </>
  )
}

export default AddSiteBOQ