import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { createGinApi, createVendorApi, deleteItemByIdApi, fileUploadApi, getGinByIdApi, getItemsApi,  getvendorsApi,updateGinApi, updateVendorByIdApi, createGinGoodsReturnApi, createVendorGinItemApi, getVendorGinItemsApi, deleteGInItemByIdApi } from '../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import { addGinItemInitialValue, addGinItemValidationSchema,  ginAddInitalValue, ginAddValidationSchema,vendorAddInitailValue, vendorAddValidationSchema } from '../../utils/Validation';
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import VendorAddModal from "../../components/modal/VenderAddModal";
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { formatPrice,getCommonCanva,getRoundOff } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import { S3_URL } from '../../utils/BaseUrl';
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import GinItemAddModal from '../../components/modal/GinItemAddModal';
import ImageViewModal from '../../components/modal/ImageViewModal';

function AddGIN() {
  const [addItemLoader, setAddItemLoader] = useState(false)
  const {langPreference} = useUserContext()
  const [addLoader, setAddLoader] = useState(false)
  const sigCanvas = useRef({});
  const [submitClick, setSubmitClick] = useState(false);
  const [convertLoader, setConvertLoader] = useState(false)
  const addItemModalId = 'addItemModalId';
  const addVendorModalId = 'addVendorModalId';
  const ItemDeleteModalId = 'itemDeleteModalId';
  const submitConfirmModal = "submitConfirmModal1"
  const statusModalId = "statusModal1"
  const convertConfirmModal = "convertConfirmModal1"
  const viewImageModalId = 'viewGinImageModalId';
  const navigate = useNavigate();
  const params = useParams();
  const { ginId,type } = params;
  const [selectItemId, setSelectItemId] = useState();
  const [ginDetails, setGinDetails] = useState({})
  const { userDetails } = useUserContext();
  const [taxItems, setTaxItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [terms, setTerms] = useState()
  const isGoodReturn = type=="goods-return"
  //items =================================================================

  const handleItemDelete = async () => {
    try {
      const res = await deleteItemByIdApi(selectItemId)
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(ItemDeleteModalId).click()
        let items = ginFormik?.values?.items?.filter(ele => { return (ele.itemId !== selectItemId) })
        ginFormik.setFieldValue("items", items)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const filteritems = async (inputValue, cb) => {
    try {
      const res = await getItemsApi(inputValue, "", "", ginFormik.values.vendorId)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.items?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const itemsOnChange = debounce(filteritems, 1000);

  const itemsOptions = (inputValue, callback) => {
    itemsOnChange(inputValue, callback);
  }

  const itemSelectHandler = (e,data) => {
    const item = {
      selected: true,
      itemId: data._id,
      _id: data._id,
      inventoryItemId:e?.inventoryItemId,
      availableQty:e?.availableQty,
      itemDetails:{...e?.itemDetails,_id: data._id},
    }
    ginFormik.setFieldValue("items", [...ginFormik.values.items, item])
  }

  const itemsSubmit = async (values) => {
    // console.log("values",values);
    try {
      setAddItemLoader(true)
      const res = await createVendorGinItemApi({ ...values, vendorId: ginFormik.values.vendorId })
      if (res.status === 200) {
        toast.success(res.data.message)
        if(selectItemId && ginFormik?.values?.items){
          const editItemArr = ginFormik?.values?.items?.map(item=>{
              if(item?.itemId==res.data?.data?._id){
              return { 
                ...item,
                unitType:item?.unitType?.toLowerCase()=="other" ? item?.otherUnit : item?.unitType,
                otherUnit:"",
                selected: true,
                itemDetails: res.data?.data}
              }else{
                return item
              }
          })
          ginFormik.setFieldValue("items",editItemArr)
        }else{
          itemSelectHandler(values,res?.data?.data?.data)
        }
        setSelectItemId('')
        document.getElementById(addItemModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false)
  }

  const removeItem = async (itemId) => {
    ginFormik.setFieldValue("items", [...ginFormik?.values?.items?.filter(ele => { return (ele.itemId !== itemId) })])
    document.getElementById(addItemModalId).click()
    try {
      const res = await deleteGInItemByIdApi( itemId, ginFormik.values.vendorId)
      if (res.status === 200) {
        toast.success(res.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const itemFormik = useFormik({
    initialValues: addGinItemInitialValue,
    onSubmit: itemsSubmit,
    validationSchema: addGinItemValidationSchema(langPreference)
  })

  //vendor =================================================================

  const handleSelectDate = (e, eleType) => {
    if (eleType === 'dueDate') {
      ginFormik.setFieldValue("dueDate", new Date(e).toISOString())
    } else {
      ginFormik.setFieldValue("createDate", new Date(e).toISOString())
    }
  };

  const handleVendorSelect = (e) => {
    if (e.__isNew__) {
      VendorFormik.resetForm();
      VendorFormik.setFieldValue("name", e.value);
      ginFormik.resetForm();
      return document.getElementById("vendorCreate").click()
    } else {
      ginFormik.setFieldValue("vendorDetails", e.value)
      ginFormik.setFieldValue("vendorId", e.value?._id)
    }
  }



  const filtervendors = async (inputValue, cb) => {
    try {
      const type = "Sub-Contractor"
      const res = await getvendorsApi(inputValue,"","",type)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const vendorOnChange = debounce(filtervendors, 1000);

  const vendorOptions = (inputValue, callback) => {
    vendorOnChange(inputValue, callback);
  }


  const vendorSubmit = async (values) => {
    try {
      setAddItemLoader(true);
      if(values.city.toLowerCase()!="other"){
        values.otherCity = ""
      }
      const res = values?._id ? await updateVendorByIdApi({ ...values, siteId: userDetails?.siteId?._id }) : await createVendorApi({ ...values, siteId: userDetails?.siteId?._id });
      if (res.status === 200) {
        toast.success(res.data.message)
        const vendor = res.data?.data

        ginFormik.setFieldValue("vendorDetails", vendor)
        ginFormik.setFieldValue("type", vendor?.type)
        ginFormik.setFieldValue("vendorId", vendor?._id)
        document.getElementById(addVendorModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false);
  }

  const VendorFormik = useFormik({
    initialValues: vendorAddInitailValue,
    onSubmit: vendorSubmit,
    validationSchema: vendorAddValidationSchema(langPreference)
  })


  // purchase orders =================================

  const checkHandler = (e, ele) => {
    let items = ginFormik.values.items.map((item, i) => {
      let isEdit = ginFormik?.values?._id
      let isExist = isEdit ? item._id === ele._id :item.itemId === ele.itemId
      if (isExist) {
        return {
          ...item,
          selected: e.target.checked
        }
      } else {
        return item;
      }
    });

    ginFormik.setFieldValue("items", items)
  }

  const poSubmit = async (values) => {
    let payload = JSON.parse(JSON.stringify(values));
    payload.items = payload.items.filter(item => item.selected)
    if (payload.items.length === 0) {
      return toast.error("Atleast select one item")
    }

    if(isGoodReturn){
      payload.ginId = ginId
    }
  setAddLoader(true);
    try {

      const res = values?._id ?(isGoodReturn ? await createGinGoodsReturnApi(payload) : await updateGinApi(payload)) : await createGinApi(payload);
      if (res.status === 200) {
        // toast.success(res.data.message);
        document.getElementById(submitConfirmModal).click()
        let message = isGoodReturn ? "Successfully submitted" :`GIN ${res.data?.data?.ginNumber || ""} is successfully ${ginDetails?.status === "submitted"? "updated.": "submitted."}`
        setConfirmationMsg(res.data.message)
        document.getElementById('statusModalBtn').click()
       
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }

    if (values.convertToPurchase) setConvertLoader(false)
    else setAddLoader(false)
  }

  const ginFormik = useFormik({
    initialValues: ginAddInitalValue,
    onSubmit: poSubmit,
    validationSchema: ginAddValidationSchema(langPreference)
  })

  
  

  const getGinById = async (id) => {
    const res = await getGinByIdApi(id)
    if (res.status === 200) {
      const ginData = res.data?.data
      ginFormik.setFieldValue("_id", ginData?._id)
      ginFormik.setFieldValue("vendorDetails", ginData.vendor)
      ginFormik.setFieldValue("vendorId", ginData.vendor._id)      
      ginFormik?.setFieldValue("ginType",isGoodReturn ? 'goodReturn' : 'GIN')
      if(!isGoodReturn){
        ginFormik.setFieldValue("signature", ginData?.signature || '')
        ginFormik.setFieldValue("createDate", ginData?.createDate)
        ginFormik.setFieldValue("remarks", ginData?.remarks || '')
      }
      let ginItems = ginData?.items
      if(isGoodReturn){
        ginItems = ginData?.items?.map(ele=>{
          
          return {
            ...ele,
            maxReturnQty:ele?.returnRemainingQty,
            selected:false,
            quantity: 0,
           
          }
        })
      }
      
      setSelectedItems(ginItems)
      // setTimeout(() => {
      if(isGoodReturn){
        ginFormik.setFieldValue("items", ginItems)
      }else{
        getItems(ginItems, ginData.vendor._id);
      }
      // }, 100)
      setGinDetails(ginData)
    }
  }

const getItems = async (items = [], id) => {
   try {
     const res = await getVendorGinItemsApi(id);
     if (res.status === 200) {
       let fetchedItem = res.data?.data?.data
      //  console.log("fetched item",fetchedItem,items);
       
       let selected = fetchedItem?.map(item => {
         if (items.filter(el => el?.itemId === item?._id).length > 0) {
           let elem = items?.filter(el => el?.itemId === item?._id)[0]
           return {
             selected: true,
             ...elem,
             availableQty:elem?.itemDetails?.availableQty,
             itemDetails:{
              ...elem?.itemDetails,
              localName:elem?.itemDetails?.uniqueItems?.localName,
              description:elem?.itemDetails?.uniqueItems?.description
             }
           }
         } else {
           return {
             selected: false,
             itemId: item?._id,
             _id: item?._id,
             inventoryItemId:item?.inventoryItemId,
             availableQty:item?.availableQty,
             quantity: '',
             unitPrice: item?.unitPrice,
             itemDetails: item,
           }
         }
       })

       ginFormik.setFieldValue("items", selected)
     }
   } catch (error) {

   }
 }


  useEffect(() => {
    if (ginId) {
      getGinById(ginId)
    }
  }, [])

  useEffect(() => {
    let taxItem = {};
    ginFormik.values.items?.forEach(item => {
      if (taxItem[item?.itemDetails?.tax]?.length > 0) {
        taxItem[item?.itemDetails?.tax].push(item)
      } else {
        taxItem[item?.itemDetails?.tax] = [item];
      }
    })
    setTaxItems(taxItem)
  }, [ginFormik.values.items])


useEffect(() => {
  if(!isGoodReturn && ginFormik.values.vendorId){
    getItems(ginId ? selectedItems : [],ginFormik.values.vendorId)
  }
 }, [ginFormik.values.vendorId])

  const getTotalPoAmt = (items) => {
    let amt = 0
    items.forEach(item => {
      amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
    })
    return formatPrice(amt)
  }



  const handleConfirmation = ()=>{
    ginFormik.handleSubmit();
  }

  const checkValidation = (btn)=>{
    const errors = Object.keys(ginFormik.errors)
    if(errors?.length>0){
      ginFormik.handleSubmit()
    }else{
      document.getElementById(btn).click()
    }
  }

  const handleApprovalModalClose=()=>{
     navigate("/gin")
  }



const clear = () => {
  sigCanvas.current.clear();
};

const save = async () => {
  try {

    const result = await getCommonCanva(sigCanvas)
    if(result?.status){
      const formData = new FormData();
      formData.append('file', result?.blob, `gin${new Date().getTime()}.png`);
      formData.append("type", "gin")
      const res = await fileUploadApi(formData)
      if (res.status === 200 && res.data?.status === 1) {
        toast.success("File uploaded");
  
        ginFormik.setFieldValue("signature", res.data?.data)
        document.getElementById("signatureClose").click()
      }
    }else{
      toast.error("File failed");
    }
  } catch (error) {
    console.log(error)
  }
}


  return (
    <>
      <div className="dash-nav1">
        <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference?.back || "Back"}</span></div>
        <div className="align-items-center d-block d-lg-flex justify-content-between">
          <h3 className="mb-0 font-blue">{isGoodReturn ? langPreference?.goods_return || "Goods Return" : (ginId ? langPreference?.edit_gin ||  "Edit GIN" : langPreference?.create_gin || "Create New GIN")}</h3>
          {ginFormik.values.vendorId && <div>
            <button type="button" disabled={addLoader} className="mb-2 mb-sm-2 sbm-purchase" onClick={() => {
              if (ginFormik.values?.items.filter(item => item.selected)?.length) checkValidation("submitModalBtn1")
              else toast.error(langPreference?.please_select_items || "Please select items")
            }}>
              {addLoader ? <Spinwheel sizeClass={"spin-small"} /> :langPreference?.submit || "Submit"}
            </button>
          </div>}
        </div>
      </div>
      <div className="dash-table">
        <div className="container">
          <div className="row gap-3 gap-xl-0">
            <div className="col-12 col-xl-3">
              <div className="vendor-detail ">
                <div className='d-flex align-items-center justify-content-between bb'>
                <h6 className='mb-0 pb-0' style={{border:"none"}}>{langPreference?.subcontractor_details || "Sub-contractor details"}</h6>
                {/* <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`} onClick={() => VendorFormik.resetForm()}>
                  <i className="bi bi-plus"></i> Add new Vendor
                </button> */}
                </div>
                <form action="">
                <div className="form-details">
                    <label htmlFor="">{langPreference?.subcontractor_name || "Sub-contractor name"}</label>
                    <div className='d-flex align-items-center justify-content-center mb-3 '>
                      <div className='cursor-pointer w-100' >
                        <AsyncSelect style={{ cursor: "pointer !important" }} isDisabled={ginId}  className='col w-100' placeholder="Select vendor..." cacheOptions value={{ label: ginFormik.values?.vendorDetails?.name, value: ginFormik.values?.vendorDetails }} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e) }} />
                    </div>
                    {/* <i className="form-input bi bi-plus-square ms-2" style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`}></i> */}
                    </div>
                    {ginFormik?.touched?.vendorId && ginFormik?.errors?.vendorId ? (<div className='req-error'>{ginFormik?.errors?.vendorId}</div>) : null}
                  </div>

                  {ginFormik.values.vendorId && <>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-12">
                          <label htmlFor="startDate">{langPreference?.date_of_issue || "Date of issue"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-select cursor-pointer' name='createDate' selected={ginFormik.values?.createDate} onChange={(e) => { handleSelectDate(e, "createDate") }} maxDate={new Date(ginFormik.values?.dueDate)} />
                          <span id="startDateSelected"></span>

                        </div>
                      </div>
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.gst_number || "GST number"}</label>
                      <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={ginFormik.values?.vendorDetails?.gstNumber} />
                    </div>
                    {ginFormik?.values?.type!="Vendor" &&
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.pan_number || "PAN number"}</label>
                      <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={ginFormik.values?.vendorDetails?.panNumber} />
                    </div>}

                    <h6 className="mt-4">{langPreference?.subContractorAddress || "Sub-contractor address"}</h6>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.address_flat || "Flat, House no., Building, Company, Apartment"}</label>
                      <input type="text" className="form-control" disabled value={ginFormik.values?.vendorDetails?.vendorAddressId?.address1} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.address_area || "Area, Street, Sector, Village"}</label>
                      <input className="w-100 form-control" type="text" placeholder="Enter area..." disabled value={ginFormik.values?.vendorDetails?.vendorAddressId?.address2} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.state || "State"}</label>
                      <select className="form-control" aria-label="Small select example" disabled value={ginFormik.values?.vendorDetails?.vendorAddressId?.state}>
                        <option value={ginFormik.values?.vendorDetails?.vendorAddressId?.state}>{ginFormik.values?.vendorDetails?.vendorAddressId?.state}</option>
                      </select>
                    </div>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="startDate">{langPreference?.city || "City"}</label>
                          <select className="form-control" disabled aria-label="Small select example" value={ginFormik.values?.vendorDetails?.vendorAddressId?.city}>
                            <option value={ginFormik.values?.vendorDetails?.vendorAddressId?.city}>{ginFormik.values?.vendorDetails?.vendorAddressId?.city}</option>
                          </select>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endDate">{langPreference?.pincode || "Pincode"}</label>
                          <input className="form-control" type="text" placeholder="Enter pin" disabled value={ginFormik.values?.vendorDetails?.vendorAddressId?.pinCode} />
                        </div>
                      </div>
                    </div>
                    <hr />                   
                    
                  </>}
                </form>
              </div>
            </div>
            <div className="col-12 col-xl-5">
              <div className="items-detail-box position-relative ">
                <div className='d-flex justify-content-between align-items-center bb'>
                <h6 className='mb-0 pb-0' style={{border:"none"}}>{langPreference?.items_details || "Items details"}</h6>
                {ginFormik.values.vendorId && !isGoodReturn && <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.resetForm(); setSelectItemId("") }}>
                  <i className="bi bi-plus"></i> {langPreference?.add_new_item || "Add new Item"}
                </button>}
                </div>

                <table className="table table-responsive">
                  <thead>
                    <tr className='w-100'>
                      <th scope="col" className='w-50'>{langPreference?.items || "ITEMS"}</th>
                      <th scope="col">{isGoodReturn ? langPreference?.return_qty || "RETURN QTY" : langPreference?.qty || "QTY"}</th>
                      <th scope="col">{langPreference?.unit || "UNIT"}</th>
                      {isGoodReturn ? 
                     <th scope="col">{langPreference?.issued_qty || "Issued Qty"}</th>:
                     <th scope="col">{langPreference?.available_quantity || "Available Qty"}</th>  
                    }
                     {!isGoodReturn && <th scope="col">{langPreference?.edit || "Edit"}</th>}  
                    </tr>
                  </thead>
                  <tbody>
                    {ginFormik.values?.vendorId && <>
                      {ginFormik?.values?.items?.map((ele, index) => {
                        
                        return (<tr>
                          <td><td><input className="form-check-input" type="checkbox" checked={ginFormik?.values?.items?.[index]?.selected} name={`items[${index}].selected`} id="selected" onChange={(e) => { checkHandler(e, ele) }} /></td>
                            <td style={{ overflowWrap: "anywhere" }} className={`${(ele?.itemDetails?.availableQty <= ele?.itemDetails?.minQty && !isGoodReturn) && "text-danger"}`}>{ele?.itemDetails?.name}</td>
                           </td>
                          <td>
                            {/* <div className="qt-box">120</div> */}
                            <div>
                              <input type="text" className="form-control qt-box" name={`items[${index}].quantity`} id="quantity" value={ginFormik?.values?.items?.[index]?.quantity} onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                ginFormik.handleChange(e);
                              }
                              }} onBlur={ginFormik.handleBlur} disabled={!ginFormik?.values?.items?.[index]?.selected} />
                              {ginFormik?.touched?.items?.[index]?.quantity && ginFormik?.errors?.items?.[index]?.quantity ? (<div className='req-error'>{ginFormik?.errors?.items?.[index]?.quantity}</div>) : null}</div>
                          </td>
                          <td>
                            <div>
                              <input type="text" className="form-control qt-box" name={`items[${index}].itemDetails.unitType`} id="unitType" value={ginFormik?.values?.items?.[index]?.itemDetails?.unitType}  disabled={true} />
                              </div>
                          </td>
                          {isGoodReturn ?
                            <td>
                              <div>
                                <input type="text" className="form-control qt-box" name={`items[${index}].returnRemainingQty`} id="returnRemainingQty" value={ginFormik?.values?.items?.[index]?.returnRemainingQty} disabled={true} />
                              </div>
                            </td> :
                            <td>
                              <div>
                                <input type="text" className={`form-control qt-box ${ele?.itemDetails?.availableQty <= ele?.itemDetails?.minQty && "text-danger"}`} name={`items[${index}].availableQty`} id="availableQty" value={ginFormik?.values?.items?.[index]?.availableQty} disabled={true} />
                              </div>
                            </td>
                          }

                      {!isGoodReturn && 
                          <td><i data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.setValues(ele?.itemDetails);setSelectItemId(ele?.itemId) }} className="bi bi-pencil-square point"></i></td>
                          }
                        </tr>)
                      })
                      }
                    </>}

                  </tbody>
                </table>
                <hr />
                {ginFormik.values.vendorId && <div className="form-details">
                  <label htmlFor="remarks" className="form-label">{langPreference?.remarks || "Remarks"}</label>
                  <textarea className="w-100 form-control" type="text" name={`remarks`} placeholder={langPreference?.write || "Enter note..."} disabled="" onChange={ginFormik.handleChange} value={ginFormik?.values?.remarks} />
                  {ginFormik?.touched?.remarks && ginFormik?.errors?.remarks ? (<div className='req-error'>{ginFormik?.errors?.remarks}</div>) : null}
                </div>}
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="order-review">
                <div className="d-flex bb justify-content-between align-items-center">
                  <h6>{langPreference?.preview || "Preview"}</h6>

                </div>
                {ginFormik.values.vendorId && <div className="org">
                  <div className="d-flex  justify-content-between align-items-center">
                    <h4 className="mb-3">{ginFormik.values?.vendorDetails?.name}</h4>
                </div>

                  {ginDetails?.ginNumber &&!isGoodReturn && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.gin_number || "GIN number"}</p>
                    <p className="light">{ginDetails?.ginNumber}</p>
                  </div>}

                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.date_of_issue || "Date of issue"}</p>
                    <p className="light">{moment(ginFormik.values?.createDate).format("DD/MM/YYYY")}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.gst_number || "GST number"}</p>
                    <p className="light">{ginFormik.values?.vendorDetails?.gstNumber}</p>
                  </div>
                  {ginFormik.values?.vendorDetails?.type!="Vendor" &&
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.pan_number || "PAN number"}</p>
                    <p className="light">{ginFormik.values?.vendorDetails?.panNumber}</p>
                  </div>}
                  {ginDetails?.status  &&!isGoodReturn && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.status || "Status"}</p>
                    <p className="light">{ginDetails?.status}</p>
                  </div>}
                  <div className="add-box">
                    <h5>{langPreference?.address || "Address"}:</h5>
                    {ginFormik.values.vendorId && <p className="mb-0">{ginFormik.values?.vendorDetails?.vendorAddressId?.address1}, {ginFormik.values?.vendorDetails?.vendorAddressId?.address2}, {ginFormik.values?.vendorDetails?.vendorAddressId?.city}, {ginFormik.values?.vendorDetails?.vendorAddressId?.state}, {ginFormik.values?.vendorDetails?.vendorAddressId?.pinCode}</p>}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6>{langPreference?.items || "Items"} ({langPreference?.count || "count"}: {ginFormik?.values?.items?.filter(item => item?.selected)?.length})</h6>
                    {/* <i className="bi bi-pencil-square"></i> */}
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th  scope="col">{langPreference?.name || "NAME"}</th>
                        <th scope="col">{langPreference?.qty || "QTY"}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {ginFormik.values?.vendorId && <>
                        {ginFormik?.values?.items?.filter(item => item?.selected)?.map((ele, index) => {
                          // console.log(ele)
                          return (<tr>
                            <td style={{ overflowWrap: "anywhere" }}>{ele?.itemDetails?.name}</td>
                            <td>
                              {ele?.quantity}
                            </td>
                          </tr>)
                        })
                        }
                      </>}
                    </tbody>
                  </table>
                </div>}
                {ginFormik.values.remarks && <div className="add-box">
                  <h5>Remarks:</h5>
                   <p className="mb-0">{ginFormik.values?.remarks}</p>
                </div>}



                {ginFormik.values.vendorId && <div>
                  <div className='d-flex align-items-center'><div className='fs-5 fw-bold'>{isGoodReturn ?  langPreference?.store_keeper_signature || "Signature of  Store keeper" :langPreference?.signature_subcontractor || "Signature of  sub-contractor" } </div> &nbsp; <span className='req-error'>*</span></div>
                  <div className='py-2'>
                    <div className="d-flex border px-2 py-1 justify-content-between align-items-center ">
                      <div className="">
                        <div className='border rounded-image d-flex justify-content-center align-items-center'>
                          <img src={S3_URL + ginFormik.values?.signature} alt="" style={{ maxHeight: "85%", maxWidth: "85%" }} />
                        </div>
                      </div>

                      <div className='signatureCanvasParent'>
                        <Popup
                          modal
                          trigger={<div className='create-order cursor-pointer' >
                            {langPreference?.add_signature || "Add Signature"}
                          </div>}
                          closeOnDocumentClick={false}

                        >
                          {close => (
                            <>
                              <SignaturePad
                              penColor='#2196F3'
                                ref={sigCanvas}
                                canvasProps={{
                                  className: "signatureCanvas"
                                }}
                              />

                              <div className='bg-dark-soft p-2'>
                                <button className='btn btn-primary mx-1' id="signatureSave" onClick={save} >{langPreference?.upload || "Upload"}</button>
                                <button className='btn btn-primary mx-1' id="signatureClear" onClick={clear}>{langPreference?.clear || "Clear"}</button>
                                <button className='btn btn-primary mx-1' id="signatureClose" onClick={close}>{langPreference?.close || "Close"}</button>
                              </div>
                            </>
                          )}
                        </Popup>
                      </div>
                    </div>
                    {(ginFormik?.touched?.signature || submitClick) && ginFormik?.errors?.signature ? (<div className='req-error'>{ginFormik?.errors?.signature}</div>) : null}
                  </div>
                </div>}

                {ginFormik.values?.signature && <div className='p-1'>
                  <div className="row row-cols-1 row-cols-md-4 mt-3">
                    <div>
                  <div className='d-flex justify-content-center align-items-center border cursor-pointer' data-bs-toggle="modal" data-bs-target={`#${viewImageModalId}`} style={{ height: "80px" }} >
                    <img src={S3_URL + ginFormik.values?.signature} alt="" className='img-fluid w-100 h-100' />
                  </div>
                    <div className='text-center'>{langPreference?.signature || "Signature"}</div>
                    </div>
                  </div>
                </div>}

                

              </div>
            </div>
          </div>
        </div>
      </div>

      <GinItemAddModal formik={itemFormik} modalId={addItemModalId} addLoader={addItemLoader} type="ginItem" removeItem={removeItem} selectItem={selectItemId} />
      <VendorAddModal formik={VendorFormik} modalId={addVendorModalId} addLoader={addItemLoader} />
      <div className="col">
        <div className="btn btn-primary hidden" style={{ display: "none" }} data-bs-toggle="modal" id='vendorCreate' data-bs-target={`#${addVendorModalId}`} onClick={() => { itemFormik.resetForm() }} >Create Vendor</div>
      </div>
      <ConfirmationModal modalId={ItemDeleteModalId} handleConfirm={handleItemDelete} message={langPreference?.are_you_sure_to_delete || <>Are you sure you want to <br /> Delete ?</>} />
      {/* for po approval */}
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={()=>handleConfirmation(false)} message={langPreference?.submit_confirmation || <>Are you sure you want to <br /> Submit ?</>} />
       <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
        <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleApprovalModalClose}
        />
      <ImageViewModal imgUrl={S3_URL + ginFormik.values?.signature} modalId={viewImageModalId} />

    </>
  )
}

export default AddGIN