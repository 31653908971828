import React, { useEffect, useState } from 'react'
import { approvePurchaseOrderApi, convertToPurchaseApi, getSiteBoqByIdApi, makeCopyApi,downloadPOPdf, approveGinByIdApi, approveOrRejectSiteBoqApi, } from '../../utils/ApiEndpointFunctions'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import toast from 'react-hot-toast';
import Spinwheel from '../../components/common/Spinwheel';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import { S3_URL } from '../../utils/BaseUrl';
import * as XLSX from 'xlsx-js-style';

function ViewSiteBoq() {
  const params = useParams();
  const { userDetails,langPreference } = useUserContext();
  const { id } = params;
  let addressKey = ["address1", "address2", "city", "state", "pinCode"]
  const [boqDetails, setBoqDetails] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"
  const confirmMakeAcopy = "confirmMakeAcopy";
    const [updatedStatus,setUpdatedStatus] = useState(null)
  const [loader, setLoader] = useState({
    approve: false,
    makeAcopy: false,
    convertToPurchase: false
  })
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [navigationRoute, setNavigationRoute] = useState("")
  const [download,setDownload] = useState(false)
  const location = useLocation();
  
  const getSiteBoqById = async (id) => {
    try{
      const res = await getSiteBoqByIdApi(id)
      
      if (res.status === 200) {
        const podata = res.data?.data
        setBoqDetails(podata);
      }
    }catch(err){
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  

  const handleApproval = async () => {
    try {
      const res = await approveOrRejectSiteBoqApi({ id: id,status:updatedStatus })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(res?.data?.message || `BOQ ${boqDetails?.boqNumber || ""} is successfully ${updatedStatus}.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute('')
        getSiteBoqById(id)
      }
    } catch (error) {
      console.log(error)
      if(error && error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        }else{
          toast.error("something went wrong");
        }
    }
  }


  const handleCloseStatusModal = () => {
    if(navigationRoute){
      navigate(navigationRoute)
    }
  }

  useEffect(() => {
    if (id) {
      getSiteBoqById(id)
    }
  }, [])

const totalAmount =(items)=>{
   let total =0
   items?.forEach(ele=>{
     total+=Number(ele?.quantity)*Number(ele?.unitPrice)
   })
   return total
}

const totalCount =(items)=>{
  let total =0
  items?.forEach(ele=>{
    total+=Number(ele?.quantity)
  })
  return total
}

const exportToExcel = async () => {
  if (boqDetails?.boqNumber) {
     try {
        setDownload(true);
           const workbook = XLSX.utils.book_new();
           const worksheetData = [];
           const defaultHeight = [];
           const merges = [];
           const boldCellStyle = { font: { bold: true } }
           const borderCellStyle = {
              border: {
                 right: {
                    style: "thin",
                    color: "000000"
                 },
                 left: {
                    style: "thin",
                    color: "000000"
                 },
                 top: {
                    style: "thin",
                    color: "000000"
                 },
                 bottom: {
                    style: "thin",
                    color: "000000"
                 },
              }
           }
           const centerWithBoldStyle = {
              font: { bold: true },
              alignment: {
                 vertical: "center",
                 horizontal: "center",
                 wrapText: '1', // any truthy value here
              },
           }

           const defaultZooming = [
              { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 },
              { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }
           ];

           const addRow = (ele,index) => {
              worksheetData.push([
                { v: ele?.uniqueBoqItemId || "", s: borderCellStyle },
                { v: ele?.sNo || "", s: borderCellStyle },
                { v: ele?.nameAndDetails || "", s: borderCellStyle },
                 { v: ele?.unitType || '', s: borderCellStyle },
                 { v: ele?.quantity ? Number(ele?.quantity) : '', s: borderCellStyle },
                 { v: ele?.unitPrice ? Number(ele?.unitPrice).toFixed(2)  :"", s: borderCellStyle },
                 { v: ele?.amount ? Number(ele?.amount)?.toFixed(2) :"", s: borderCellStyle },
              ]);
              defaultHeight.push({ hpx: 40 }, { hpx: 40 }, { hpx: 40 }, { hpx: 40 }, { hpx: 40 },{ hpx: 40 });
           };
           if (userDetails?.siteId?.companyId?.name) {
            let startRow = worksheetData.length
            worksheetData.push([{ v: `Company: ${userDetails.siteId.companyId.name}`, s: {...centerWithBoldStyle} }]);
            merges.push({
                s: { r: startRow, c: 0 }, // start row and column
                e: { r: startRow, c: 6 }  // end row and column
            });
         }

         if (userDetails?.siteId?.name) {
            let startRow = worksheetData.length
            worksheetData.push([{ v:  `Site: ${userDetails.siteId.name}`, s: {...centerWithBoldStyle} }]);
            merges.push({
                s: { r: startRow, c: 0 }, // start row and column
                e: { r: startRow, c: 6 }  // end row and column
            });
         }

           const vendorStartRow = worksheetData.length;
           worksheetData.push([{v:`BOQ ${boqDetails?.boqNumber || ""}`,s: { ...boldCellStyle,...centerWithBoldStyle } }]);
           merges.push({
              s: { r: vendorStartRow, c: 0 }, // start row and column
              e: { r: vendorStartRow, c: 6 }  // end row and column
          });
          worksheetData.push([
            { v: 'Contract Date', s: { ...boldCellStyle,  } }, 
            { v: boqDetails?.contractDate ? moment(boqDetails?.contractDate).format("DD/MM/YYYY") : '', s: {} },
            { v: 'Date of Start (as per Contract)', s: { ...boldCellStyle, } }, 
            { v: boqDetails?.contractStartDate ? moment(boqDetails?.contractStartDate).format("DD/MM/YYYY") : '', s: {} },
            { v: 'Date of Completion (as per contract)', s: { ...boldCellStyle, } }, 
            { v: boqDetails?.completionDate ? moment(boqDetails?.completionDate).format("DD/MM/YYYY") : '', s: {} },
         ]);
         worksheetData.push([
          { v: 'Actual Date', s: { ...boldCellStyle, } }, 
          { v: boqDetails?.actualDate ? moment(boqDetails?.actualDate).format("DD/MM/YYYY") : '', s: {} },
          { v: 'Category', s: { ...boldCellStyle, } }, 
          { v: boqDetails?.category ||  '', s: {} },
          { v: 'Increase/Decrease', s: { ...boldCellStyle, } }, 
          { v: boqDetails?.incDesc ||  '', s: {} },
         ])
           worksheetData.push(
              ["MMS ID","SNo","Name and Details","Unit","Qty","Rate","Amount"].map(item => ({ v: item, s: {...centerWithBoldStyle,...borderCellStyle} }))
           )
           boqDetails?.items.forEach((ele,index) => {
              addRow(ele,index);
           });
           worksheetData.push([
            { v: 'Total amount', }, {v:""},{v:""},{v:""},{v:""},{v:""}, { v:  formatPrice(boqDetails?.amount || Number(totalAmount(boqDetails?.items))) }].map(ele=>{return {...ele,s: { ...boldCellStyle, ...borderCellStyle }}}));
           if(boqDetails?.grandTotal){
             worksheetData.push([
               { v: 'Grand Total' }, {v:""},{v:""},{v:""},{v:""},{v:""},{ v: formatPrice(boqDetails?.grandTotal) }].map(ele=>{return {...ele,s: { ...boldCellStyle, ...borderCellStyle }}}));
           }
           if(boqDetails?.incDesc){
             worksheetData.push([
             { v: 'Inc/Dec Value' }, {v:""},{v:""},{v:""},{v:""},{v:""},{ v: formatPrice((Number(boqDetails?.grandTotal)-Number(boqDetails?.amount)) || true) }].map(ele=>{return {...ele,s: { ...boldCellStyle, ...borderCellStyle }}}));
           }
              
           const startRow = worksheetData.length;
           worksheetData.push([{v:`Powered by MMS`,s: { ...boldCellStyle,...centerWithBoldStyle,} }]);
           merges.push({
              s: { r: startRow, c: 0 }, // start row and column
              e: { r: startRow, c: 5 }  // end row and column
          });

           const worksheet = XLSX.utils.aoa_to_sheet([]);
           XLSX.utils.sheet_add_aoa(worksheet, worksheetData, { origin: "A1", cellStyles: true });
           worksheet['!cols'] = defaultZooming;
           worksheet['!rows'] = defaultHeight;
           worksheet['!merges'] = merges;
           XLSX.utils.book_append_sheet(workbook, worksheet, 'BOQ');
           XLSX.writeFile(workbook, 'BOQ.xlsx');
           setDownload(false);
           toast.success("Success")
     } catch (error) {
        toast.error("Failed to download")
        console.log("error", error);
     }
     setDownload(false);
  }
};

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference?.back || "Back"}</span></div>
            <div className="main-content">
              
              <div className="row d-flex justify-content-center">
                <div className="card col-lg-10 col-md-12 col-xxl-11 my-3 p-5">
                
                  <div className="">
                  <div className='fs-3 fw-normal'>
                    {langPreference?.create_boq || "Site details"}
                  </div>
                  <div className="row row-cols-2">
                    <div className="">{langPreference?.site_name || "Site name"}</div>
                    <div>{boqDetails?.site?.[0]?.name}</div>
                  </div>
                  <div className="row row-cols-2">
                    <div className="">{langPreference?.site_address || "Site address"}</div>
                    <div>{boqDetails?.site?.[0]?.addressId?.address1}</div>
                  </div>
                   
                  </div>
                  <hr />
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference?.boq_number || "BOQ Number"}</div>
                    <div>{boqDetails?.boqNumber}</div>
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference?.contract_date || "Contract Date"}</div>
                    <div>{moment(boqDetails?.contractDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference?.date_of_start_contract || "Date of Start (as per Contract)"}</div>
                    <div>{moment(boqDetails?.contractStartDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference?.date_of_completion_contract || "Date of Completion (as per contract)"}</div>
                    <div>{moment(boqDetails?.completionDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference?.date_of_start_actual || "Date of Start (Actual)"}</div>
                    <div>{moment(boqDetails?.actualDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference?.category || "Category"}</div>
                    <div>{boqDetails?.category}</div>
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference?.increase_decrease || "Increase/Decrease"}</div>
                    <div>{boqDetails?.incDesc}</div>
                  </div>
                  <hr />
                  <h3>
                  {langPreference?.items || "Items"} ({boqDetails?.items?.length || 0})
                  </h3>
                  <div className='table-responsive'>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                        <th className='col-2'>{langPreference?.mms_id || "MMS ID"}</th>
                        <th>{langPreference?.serial_no || "S.No"}</th>
                          <th scope="col">{langPreference?.item_name_details || "Item Name and Details"}</th>
                          <th scope="col-2">{langPreference?.unit || "UNIT"}</th>
                          <th scope="col-2"><div className='d-flex justify-content-end'>{langPreference?.qty || "QTY"}</div></th>
                          <th scope="col-2"><div className='d-flex justify-content-end'>{langPreference?.rate || "Rate"}</div></th>
                          <th scope="col" className='col-2'> <div className='d-flex justify-content-end'>{langPreference?.amount || "Amount"}</div></th>
                        </tr>
                      </thead>
                      <tbody >
                        {boqDetails?.items?.map((ele, index) => {
                          
                          return (
                            <tr key={index}>
                              <td>{ele?.uniqueBoqItemId}</td>
                              <td>{ele?.sNo}</td>
                              <td style={{overflowWrap:"anywhere"}}>{ele?.nameAndDetails}</td>
                              <td>{ele?.unitType}</td>
                              <td><div className='d-flex justify-content-end'>{ele?.quantity}</div></td>
                              <td><div className='d-flex justify-content-end'> {ele?.unitPrice ? Number(ele?.unitPrice)?.toFixed(2) : ""}</div></td>
                              <td className=''> <div className='d-flex justify-content-end'>{ele?.amount ? Number(ele?.amount)?.toFixed(2) :(ele?.quantity && ele?.unitPrice && Number(Number(ele?.quantity)*Number(ele?.unitPrice)).toFixed(2))}</div></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                          <hr />
                          <div className='amount-box p-2 px-3'>
                          <div className="d-flex total justify-content-between align-items-center">
                                <p>{langPreference?.total_quantity || "Total quantity"}</p>
                                <p>{totalCount(boqDetails?.items || [])}</p>
                             </div>
                             <div className="d-flex total justify-content-between align-items-center">
                                <p>{langPreference?.total_amount || "Total amount"}</p>
                                <p>{formatPrice(boqDetails?.amount || totalAmount(boqDetails?.items))}</p>
                             </div>
                            {boqDetails?.grandTotal ? <div className="d-flex total justify-content-between align-items-center">
                                <p>{langPreference?.grand_total || "Grand Total"}</p>
                                <p>{formatPrice(boqDetails?.grandTotal)}</p>
                             </div> : ""}
                             {boqDetails?.incDesc ?  <div className="d-flex total justify-content-between align-items-center">
                                <p>{langPreference?.inc_dec_value || "Inc/Dec value"}</p>
                                <p>{formatPrice(Number(boqDetails?.grandTotal)-Number(boqDetails?.amount))}</p>
                             </div> :""}
                          </div>
                          <hr />
                  <div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference?.status || "Status"}</div>
                    <div className='text-capitalize'>{boqDetails?.status}</div>
                  </div>
                    {boqDetails?.createdBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.created_by || "Created By"}</div>
                      <div className='text-capitalize'>{boqDetails?.createdBy?.firstName || ""} {boqDetails?.createdBy?.lastName || ""}</div>
                    </div>}
                    {boqDetails?.createdAt && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.created_on || "Created On"}</div>
                      <div className='text-capitalize'>{boqDetails?.createdAt && moment(boqDetails?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {boqDetails?.status!="created" && boqDetails?.submittedBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.submitted_by || "Submitted By"}</div>
                      <div className='text-capitalize'>{boqDetails?.submittedBy?.firstName || ""} {boqDetails?.submittedBy?.lastName || ""}</div>
                    </div>}
                    {boqDetails?.status!="created" && boqDetails?.submittedOn && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.submitted_on || "Submitted On"}</div>
                      <div className='text-capitalize'>{boqDetails?.submittedOn && moment(boqDetails?.submittedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {boqDetails?.approvedBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.approved_by || "Approved By"}</div>
                      <div className='text-capitalize'>{boqDetails?.approvedBy?.firstName || ""} {boqDetails?.approvedBy?.lastName || ""}</div>
                    </div>}
                    {boqDetails?.approvedOn && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.approved_on || "Approved On"}</div>
                      <div className='text-capitalize'>{boqDetails?.approvedOn && moment(boqDetails?.approvedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                  </div> 
                  <hr/>         

                    
                  <div className="d-flex justify-contend-start gap-2">

                    {boqDetails?.status!== "created" && boqDetails?.status!== "approved" && boqDetails?.status!== "rejected" && userDetails?.roleId?.permission.includes("boqApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("approved")}>{langPreference?.approve || "Approve"}</div>
                    </div>}
                    {userDetails?.roleId?.permission.includes("boqUpdate") && boqDetails?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/boq/update/${id}`) }} > {langPreference?.edit || "Edit"}</button></div>}
                    {boqDetails?.status== "approved" && userDetails?.roleId?.permission.includes("boqRead") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <button disabled={download} className="btn btn-primary"  onClick={exportToExcel}>
                      {download ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.download || "Download"}
                      </button>
                    </div>}
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal modalId={confirmModalId} handleConfirm={handleApproval} message={langPreference?.approve_confirmation || <>Are you sure you want to <br /> Approve ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleCloseStatusModal}
      />
    </>
  )
}

export default ViewSiteBoq