import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { createItemApi, createPurchaseOrderApi, createVendorApi, deleteItemByIdApi, getItemsApi, getPurchaseOrderById, getPurchaseOrderByIdApi, getvendorsApi, submitAndConvertToPurchaseOrderApi, termsAndConditionsApi, updateItemByIdApi, updatePurchaseOrderApi, updateVendorByIdApi } from '../../utils/ApiEndpointFunctions';
import ItemsAddModal from '../../components/modal/ItemsAddModal';
import { useFormik } from 'formik';
import { addItemInitialValue, addItemValidationSchema, poAddInitalValue, poAddValidationSchema, vendorAddInitailValue, vendorAddValidationSchema } from '../../utils/Validation';
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import VendorAddModal from "../../components/modal/VenderAddModal";
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { formatPrice,getRoundOff } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import Select from 'react-select';

function AddPurchaseOrder() {
  const [addItemLoader, setAddItemLoader] = useState(false)
  const [addLoader, setAddLoader] = useState(false)
  const [convertLoader, setConvertLoader] = useState(false)
  const addItemModalId = 'addItemModalId';
  const addVendorModalId = 'addVendorModalId';
  const updateItemModalId = "UpdateItemModalId";
  const ItemDeleteModalId = 'itemDeleteModalId';
  const submitConfirmModal = "submitConfirmModal1"
  const statusModalId = "statusModal1"
  const convertConfirmModal = "convertConfirmModal1"
  const convertStatusModalId = "covertStatusModal1"
  const [PoEditId,setPoEditId] = useState(null)
  const navigate = useNavigate();
  const params = useParams();
  const { purchaseOrderId } = params;
  const [selectItemId, setSelectItemId] = useState();
  const [poDetails, setPoDetails] = useState({})
  const { userDetails,langPreference } = useUserContext();
  const [taxItems, setTaxItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [terms, setTerms] = useState()
  const [reLoadVendor,setReLoadVendor] = useState(false)
  

  //items =================================================================

  const handleItemDelete = async () => {
    try {
      const res = await deleteItemByIdApi(selectItemId)
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(ItemDeleteModalId).click()
        let items = poFormik?.values?.items?.filter(ele => { return (ele.itemId !== selectItemId) })
        poFormik.setFieldValue("items", items)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const filteritems = async (inputValue, cb) => {
    try {
      const res = await getItemsApi(inputValue, "", "", poFormik.values.vendorId)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.items?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const itemsOnChange = debounce(filteritems, 1000);

  const itemsOptions = (inputValue, callback) => {
    itemsOnChange(inputValue, callback);
  }

  const itemSelectHandler = (e) => {

    const item = {
      selected: true,
      itemId: e._id,
      quantity: '',
      unitPrice: 1,
      unitType:e?.unitType || "",
      otherUnit:"",
      itemDetails: e
    }
    poFormik.setFieldValue("items", [...poFormik.values.items, item])
  }

  const itemsSubmit = async (values) => {
    try {
      setAddItemLoader(true)
      if(values?.unitType?.toLowerCase()!="other"){
        values.otherUnit = ""
      }
      const res = selectItemId ? await updateItemByIdApi({ ...values, vendorId: poFormik.values.vendorId, unitPrice: 0, totalQuantity: 0,type:poFormik?.values?.type }) : await createItemApi({ ...values, vendorId: poFormik.values.vendorId, unitPrice: 1, totalQuantity:0,type:poFormik?.values?.type })
      if (res.status === 200) {
        toast.success(res.data.message)

        // if (values._id && poFormik && poFormik.values && poFormik.values.items) {
        //   poFormik.values.items = poFormik?.values?.items?.filter(ele => { return (ele.itemId !== values._id) })
        // }
        if(selectItemId && poFormik?.values?.items){
          const editItemArr = poFormik?.values?.items?.map(item=>{
            
              if(item?.itemId==res.data?.data?._id){
              return { 
                ...item,
                unitType:item?.unitType?.toLowerCase()=="other" ? item?.otherUnit : item?.unitType,
                otherUnit:"",
                selected: true,
                itemDetails: res.data?.data}
              }else{
                return item
              }
          })
          // poFormik.values.items = poFormik?.values?.items?.filter(ele => { return (ele.itemId !== values._id) })
          poFormik.setFieldValue("items",editItemArr)
        }else{
          itemSelectHandler(res.data?.data)
        }
        setSelectItemId('')
        document.getElementById(addItemModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false)
  }

  const removeItem = async (itemId) => {

    poFormik.setFieldValue("items", [...poFormik?.values?.items?.filter(ele => { return (ele.itemId !== itemId) })])
    document.getElementById(addItemModalId).click()
    try {
      const res = await deleteItemByIdApi( itemId, poFormik.values.vendorId)
      if (res.status === 200) {
        toast.success(res.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const itemFormik = useFormik({
    initialValues: addItemInitialValue,
    onSubmit: itemsSubmit,
    validationSchema: addItemValidationSchema(langPreference)
  })

  //vendor =================================================================

  const handleSelectDate = (e, eleType) => {
    if (eleType === 'dueDate') {
      poFormik.setFieldValue("dueDate", new Date(e).toISOString())
    } else {
      poFormik.setFieldValue("createDate", new Date(e).toISOString())
    }
  };

  const handleVendorSelect = (e) => {
    if (e.__isNew__) {
      VendorFormik.resetForm();
      VendorFormik.setFieldValue("name", e.value);
      poFormik.resetForm();
      return document.getElementById("vendorCreate").click()
    } else {
      poFormik.setFieldValue("vendorDetails", e.value)
      poFormik.setFieldValue("vendorId", e.value?._id)
    }
  }

  const editVendorHandler = (vendorDetails) => {
    document.getElementById("vendorCreate").click()

    let vendorValue = {
      bankAccountNo: vendorDetails?.bankAccountNo,
      bankName: vendorDetails?.bankName,
      IFSC: vendorDetails?.IFSC,
      email: vendorDetails?.email,
      gstNumber: vendorDetails?.gstNumber,
      panNumber: vendorDetails?.panNumber,
      isActive: vendorDetails?.isActive,
      name: vendorDetails?.name,
      phone: vendorDetails?.phone,
      siteId: vendorDetails?.siteId,
      _id: vendorDetails?._id,
      address1: vendorDetails?.vendorAddressId?.address1,
      address2: vendorDetails?.vendorAddressId?.address2,
      city: vendorDetails?.vendorAddressId?.city,
      createdAt: vendorDetails?.vendorAddressId?.createdAt,
      isActive: vendorDetails?.vendorAddressId?.isActive,
      pinCode: vendorDetails?.vendorAddressId?.pinCode,
      state: vendorDetails?.vendorAddressId?.state,
      type: vendorDetails?.type,
      specialization: vendorDetails?.specialization,
      nickName: vendorDetails?.nickName,
      otherCity:""
    }
    VendorFormik.setValues(vendorValue)
    VendorFormik.setFieldValue("siteId", undefined);

  }



  const filtervendors = async (inputValue, cb) => {
    try {
      const type = poFormik?.values?.type
      const res = await getvendorsApi(inputValue,"","",type)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const vendorOnChange = debounce(filtervendors, 1000);

  const vendorOptions = (inputValue, callback) => {
    vendorOnChange(inputValue, callback);
  }


  const vendorSubmit = async (values) => {
    try {
      setAddItemLoader(true);
      if(values.city.toLowerCase()!="+add city"){
        values.otherCity = ""
      }
      const res = values?._id ? await updateVendorByIdApi({ ...values, siteId: userDetails?.siteId?._id }) : await createVendorApi({ ...values, siteId: userDetails?.siteId?._id });
      if (res.status === 200) {
        toast.success(res.data.message)
        const vendor = res.data?.data

        poFormik.setFieldValue("vendorDetails", vendor)
        poFormik.setFieldValue("type", vendor?.type)
        poFormik.setFieldValue("vendorId", vendor?._id)
        document.getElementById(addVendorModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false);
  }

  const VendorFormik = useFormik({
    initialValues: vendorAddInitailValue,
    onSubmit: vendorSubmit,
    validationSchema: vendorAddValidationSchema(langPreference)
  })


  // purchase orders =================================

  const checkHandler = (e, ele) => {
    let items = poFormik.values.items.map((item, i) => {
      if (item.itemId === ele.itemId) {
        return {
          ...item,
          selected: e.target.checked
        }
      } else {
        return item;
      }
    });

    poFormik.setFieldValue("items", items)
  }

  const poSubmit = async (values) => {
    let payload = JSON.parse(JSON.stringify(values));
    payload.items = payload.items.filter(item => item.selected)

    if (payload.items.length === 0) {
      return toast.error("Atleast select one item")
    }

    delete payload.convertToPurchase;
    let amt = totalAmount(poFormik?.values?.items?.filter(item => item?.selected))

    payload.amount = Math.round(amt)
    payload.roundOffAmount = getRoundOff(amt)

    if (values.convertToPurchase) setConvertLoader(true)
    else setAddLoader(true);
    try {

      const res = values.convertToPurchase ? await submitAndConvertToPurchaseOrderApi(payload) : values?._id ? await updatePurchaseOrderApi(payload) : await createPurchaseOrderApi(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        // navigate(`/challan/Edit/${res.data?.data?._id}`);
        // if (values.convertToPurchase) navigate(`/challan/Edit/${res.data?.data?._id}`);
        // else navigate("/purchase");
        if (values.convertToPurchase){
          document.getElementById(convertConfirmModal).click()
          setPoEditId(res.data?.data?._id)
        }else {
          document.getElementById(submitConfirmModal).click()
        } 

        setConfirmationMsg(res.data.message || `Purchase Order ${res.data?.data?.poNumber || ""} is successfully ${poDetails?.status === "submitted"? "updated.": "submitted."}`)
        document.getElementById('statusModalBtn').click()
       
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }

    if (values.convertToPurchase) setConvertLoader(false)
    else setAddLoader(false)
  }

  const poFormik = useFormik({
    initialValues: poAddInitalValue,
    onSubmit: poSubmit,
    validationSchema: poAddValidationSchema(langPreference)
  })

  const getPurchaseOrderById = async (id) => {
    const res = await getPurchaseOrderByIdApi(id)
    if (res.status === 200) {
      const podata = res.data?.data
      poFormik.setFieldValue("_id", podata?._id)
      poFormik.setFieldValue("vendorDetails", podata.vendor)
      poFormik.setFieldValue("vendorId", podata.vendor._id)
      poFormik.setFieldValue("type", podata.vendor.type)
      // poFormik.setFieldValue("items", podata?.items)
      poFormik.setFieldValue("dueDate", podata?.dueDate)
      poFormik.setFieldValue("createDate", podata?.createDate)
      poFormik.setFieldValue("remarks", podata?.remarks || '')
      poFormik.setFieldValue("termsAndCondition", podata?.termsAndCondition || {title:"",description:""})
      poFormik.setFieldValue("showConvertToPurchaseBtn", podata?.showConvertToPurchaseBtn)
      setSelectedItems(podata?.items)
      // setTimeout(() => {
      getItems(podata?.items, podata.vendor._id);
      // }, 100)
      setPoDetails(podata)
    }
  }

  const getItems = async (items = [], id) => {
    try {
      const res = await getItemsApi("", "", "", id ? id : poFormik.values.vendorId);
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.items
        let selected = fetchedItem?.map(item => {
          if (items.filter(el => el.itemId === item._id).length > 0) {

            let elem = items?.filter(el => el.itemId === item._id)[0]
            // console.log(elem)
            return {
              selected: true,
              ...elem
            }
          } else {
            return {
              selected: false,
              itemId: item?._id,
              quantity: '',
              unitPrice: item?.unitPrice,
              itemDetails: item
            }
          }
        })

        poFormik.setFieldValue("items", selected)
      }
    } catch (error) {

    }
  }

  const gettermsAndConditions = async () => {
    try {
      const res = await termsAndConditionsApi();
      if (res.status === 200) {
        // console.log(res.data?.data)

        setTerms(res.data?.data?.allTerms?.map(term => ({ label: term.title, value: term})))
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    if (purchaseOrderId) {
      getPurchaseOrderById(purchaseOrderId)
    }

    gettermsAndConditions();
  }, [])

  useEffect(() => {
    let taxItem = {};
    poFormik.values.items?.forEach(item => {
      if (taxItem[item?.itemDetails?.tax]?.length > 0) {
        taxItem[item?.itemDetails?.tax].push(item)
      } else {
        taxItem[item?.itemDetails?.tax] = [item];
      }
    })
    setTaxItems(taxItem)
  }, [poFormik.values.items])



  useEffect(() => {
    if (poFormik.values.vendorId) {
      getItems(purchaseOrderId ? selectedItems : [], poFormik.values.vendorId)
    }
  }, [poFormik.values.vendorId])

  const getTotalPoAmt = (items) => {
    let amt = 0
    items.forEach(item => {
      amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
    })
    return formatPrice(amt)
  }

  const avargeTax = (items) => {
    if (items?.length > 0) {
      let tax = 0;
      items.forEach(item => {

        tax += Number(item?.itemDetails.tax)
      })
      return tax / items.length
    }
    return 0;
  }

  const totalPrice = (items) => {

    if (items?.length > 0) {
      let price = 0;
      items.forEach(item => {

        price += Number(item?.unitPrice) * Number(item?.quantity)
      })
      return price
    }
    return 0;
  }

  const totalAmount = (items) => {
    if (items?.length > 0) {
      let amt = 0
      items.forEach(item => {

        amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
      })
      return amt
    }
  }


  const handleConfirmation = (isConvert)=>{
    poFormik.values.convertToPurchase = isConvert;
    poFormik.handleSubmit();
  }

  const checkValidation = (btn)=>{
    const errors = Object.keys(poFormik.errors)
    if(errors?.length>0){
      poFormik.handleSubmit()
    }else{
      document.getElementById(btn).click()
    }
  }

  const handleApprovalModalClose=()=>{
    PoEditId ? navigate(`/challan/edit/${PoEditId}`) : navigate("/purchase")
  }

  const handleConvertModalClose =()=>{
    if(PoEditId){
      navigate(`/challan/Edit/${PoEditId}`);
    }
  }

const vendorTypeOptions=[
  {label:"Vendor",value:"Vendor"},
  {label:"Sub-Contractor",value:"Sub-Contractor"},
]

const handleVendorType =(e)=>{
  poFormik.setFieldValue("type",e?.value)
  poFormik.setFieldValue("vendorId","")
  poFormik.setFieldValue("vendorDetails",{})
  setReLoadVendor(true)
}

useEffect(()=>{
  if(reLoadVendor){
    setTimeout(() => {
      setReLoadVendor(false)
    }, 200);
  }
},[reLoadVendor])


  return (
    <>
      <div className="dash-nav1">
        <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i>  {langPreference?.back || "Back"}</span></div>
        <div className="align-items-center d-block d-lg-flex justify-content-between">
          <h3 className="mb-0 font-blue">{purchaseOrderId ? (langPreference?.edit_purchase_order || "Edit Purchase Order") : (langPreference?.create_purchase_order || "Create New Purchase Order")} </h3>
          {poFormik.values.vendorId && <div>
            {/* <button className="add-item mb-2 mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.resetForm(); setSelectItemId("") }}><i className="bi bi-plus-lg"></i> Add new item</button> */}
            <button type="button" disabled={addLoader} className="mb-2 mb-sm-2 sbm-purchase" onClick={() => {
              if (poFormik.values?.items.filter(item => item.selected)?.length) checkValidation("submitModalBtn1")
              else toast.error(langPreference?.please_select_items || "Please select items")
            }}>
              {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.submit_for_approval || "Submit for approval"}
            </button>

            {!purchaseOrderId  && <button type="button" disabled={convertLoader} className="create-order" onClick={()=>{
              if (poFormik.values?.items.filter(item => item.selected)?.length) checkValidation("convertModalBtn")
              else toast.error(langPreference?.please_select_items ||  "Please select items")
            }}>
              {convertLoader ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.submit_and_convert_to_purchase_order || "Submit and Convert to purchase"}
            </button>}
          </div>}
        </div>
      </div>
      <div className="dash-table">
        <div className="container">
          <div className="row gap-3 gap-xl-0">
            <div className="col-12 col-xl-3">
              <div className="vendor-detail ">
                <div className='d-flex align-items-center justify-content-between bb'>
                <h6 className='mb-0 pb-0' style={{border:"none"}}>{langPreference?.vendor_details || "Vendor/Sub-contractor details"}</h6>
                <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`} onClick={() => VendorFormik.resetForm()}>
                  <i className="bi bi-plus"></i> {langPreference?.add || "Add"}
                </button>
                </div>
                <form action="">
                <div className="form-details">
                    <label htmlFor="">{langPreference?.type ||"Type"}</label>
                    <div className='d-flex align-items-center justify-content-center mb-3 '>
                      <div className='cursor-pointer w-100' >
                        <Select style={{ cursor: "pointer !important" }} isDisabled={purchaseOrderId} className='col w-100' placeholder="Select type..." cacheOptions value={{label:poFormik?.values?.type,value:poFormik?.values?.type}} options={vendorTypeOptions} defaultOptions onChange={(e)=>{handleVendorType(e)}} />
                    </div>
                    </div>
                    {poFormik?.touched?.type && poFormik?.errors?.type ? (<div className='req-error'>{poFormik?.errors?.type}</div>) : null}
                  </div>
                  {poFormik?.values?.type && !reLoadVendor && <div className="form-details">
                    <label htmlFor="">{poFormik?.values?.type=="Vendor" ? langPreference?.vendor_name : langPreference?.subcontractor_name}</label>
                    <div className='d-flex align-items-center justify-content-center mb-3 '>
                      <div className='cursor-pointer w-100' >
                        <AsyncSelect style={{ cursor: "pointer !important" }} isDisabled={!poFormik?.values?.type || purchaseOrderId} className='col w-100' placeholder="Select vendor..." cacheOptions value={{ label: poFormik.values?.vendorDetails?.name, value: poFormik.values?.vendorDetails }} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e) }} />
                    </div>
                    {/* <i className="form-input bi bi-plus-square ms-2" style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`}></i> */}
                    </div>
                    {poFormik?.touched?.vendorId && poFormik?.errors?.vendorId ? (<div className='req-error'>{poFormik?.errors?.vendorId}</div>) : null}
                  </div>}

                  {poFormik.values.vendorId && <>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="startDate">{langPreference?.po_date || "PO date"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-select cursor-pointer' name='createDate' selected={poFormik.values?.createDate} onChange={(e) => { handleSelectDate(e, "createDate") }} maxDate={new Date(poFormik.values?.dueDate)} />
                          <span id="startDateSelected"></span>

                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endDate">{langPreference?.po_due_date || "PO due date"} <span className='req-error'>*</span></label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Due date' className='form-select  cursor-pointer' name='dueDate' selected={poFormik.values?.dueDate ? new Date(poFormik.values?.dueDate) : ""} minDate={new Date(poFormik.values?.createDate)} onChange={(e) => { handleSelectDate(e, "dueDate") }} />
                          {poFormik?.touched?.dueDate && poFormik?.errors?.dueDate ? (<div className='req-error'>{poFormik?.errors?.dueDate}</div>) : null}
                          <span id="endDateSelected"></span>
                        </div>
                      </div>
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.gst_number ||  "GST number"}</label>
                      <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={poFormik.values?.vendorDetails?.gstNumber} />
                    </div>
                    {poFormik?.values?.type!="Vendor" &&
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.pan_number ||  "PAN number"}</label>
                      <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={poFormik.values?.vendorDetails?.panNumber} />
                    </div>}

                    <h6 className="mt-4">{poFormik?.values?.type=="Vendor" ? langPreference?.vendorAddress : langPreference?.subContractorAddress}</h6>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.address_flat || "Flat, House no., Building, Company, Apartment"}</label>
                      <input type="text" className="form-control" disabled value={poFormik.values?.vendorDetails?.vendorAddressId?.address1} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.address_area || "Area, Street, Sector, Village"}</label>
                      <input className="w-100 form-control" type="text" placeholder="Enter area..." disabled value={poFormik.values?.vendorDetails?.vendorAddressId?.address2} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.state || "State"}</label>
                      <select className="form-control" aria-label="Small select example" disabled value={poFormik.values?.vendorDetails?.vendorAddressId?.state}>
                        <option value={poFormik.values?.vendorDetails?.vendorAddressId?.state}>{poFormik.values?.vendorDetails?.vendorAddressId?.state}</option>
                      </select>
                    </div>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="startDate">{langPreference?.city || "City"}</label>
                          <select className="form-control" disabled aria-label="Small select example" value={poFormik.values?.vendorDetails?.vendorAddressId?.city}>
                            <option value={poFormik.values?.vendorDetails?.vendorAddressId?.city}>{poFormik.values?.vendorDetails?.vendorAddressId?.city}</option>
                          </select>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endDate">{langPreference?.pincode || "Pincode"}</label>
                          <input className="form-control" type="text" placeholder="Enter pin" disabled value={poFormik.values?.vendorDetails?.vendorAddressId?.pinCode} />
                        </div>
                      </div>
                    </div>
                    <hr />                   
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.terms_conditions || "Terms and conditions"}  <span className='req-error'>*</span></label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={poFormik?.values?.termsAndCondition?.title ? { label: poFormik?.values?.termsAndCondition?.title } : null}
                        placeholder={langPreference?.select ||"Select "}
                        isSearchable={true}
                        name="state"
                        options={terms}
                        onChange={(e) => {
                          poFormik.setFieldValue("termsAndCondition", e?.value)
                        }}
                      />
                      {poFormik?.touched?.termsAndCondition?.title && poFormik?.errors?.termsAndCondition?.title ? (<div className='req-error'>{poFormik?.errors?.termsAndCondition?.title}</div>) : null}
                    </div>
                    <p className='mb-0' style={{fontSize:"13px"}}>{poFormik?.values?.termsAndCondition?.description}</p>

                    {/* <button className="next">NEXT</button> */}
                  </>}
                </form>
              </div>
            </div>
            <div className="col-12 col-xl-5">
              <div className="items-detail-box position-relative ">
                <div className='d-flex justify-content-between align-items-center bb'>
                <h6 className='mb-0 pb-0' style={{border:"none"}}>{langPreference?.items_details || "Items details"}</h6>
                {poFormik.values.vendorId && <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.resetForm(); setSelectItemId("") }}>
                  <i className="bi bi-plus"></i> {langPreference?.add_item_service || "Add Item/Service"}
                </button>}
                </div>

                <table className="table table-responsive">
                  <thead>
                    <tr className='w-100'>
                      <th scope="col" className='w-50'>{langPreference?.items || "ITEMS"}</th>
                      <th scope="col">{langPreference?.qty || "QTY"}</th>
                      <th scope="col">{langPreference?.rate || "RATE"}</th>
                      <th scope="col">{langPreference?.gst || "GST"}</th>
                      <th scope="col">{langPreference?.amount || "AMOUNT"}</th>
                      <th scope="col">{langPreference?.edit || "EDIT"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poFormik.values?.vendorId && <>
                      {poFormik?.values?.items?.map((ele, index) => {
                        return (<tr>
                          <td><td><input className="form-check-input" type="checkbox" checked={poFormik?.values?.items?.[index]?.selected} name={`items[${index}].selected`} id="selected" onChange={(e) => { checkHandler(e, ele) }} /></td>
                           <td style={{overflowWrap:"anywhere"}} className=''>{userDetails?.language=='hi' ? ele?.itemDetails?.localName : ele?.itemDetails?.name}</td>
                           </td>
                          <td>
                            {/* <div className="qt-box">120</div> */}
                            <div>
                              <input type="text" className="form-control qt-box" name={`items[${index}].quantity`} id="quantity" value={poFormik?.values?.items?.[index]?.quantity} onChange={(e) => {
                                if (/^\d*\.?\d*$/.test(e.target.value)) {
                                  poFormik.handleChange(e);
                                }
                              }} onBlur={poFormik.handleBlur} disabled={!poFormik?.values?.items?.[index]?.selected} />
                              {poFormik?.touched?.items?.[index]?.quantity && poFormik?.errors?.items?.[index]?.quantity ? (<div className='req-error'>{poFormik?.errors?.items?.[index]?.quantity}</div>) : null}</div>
                          </td>
                          <td>
                            {/* <div className="un-box">5500.00</div> */}
                            <div>
                              <input type="text" className="form-control un-box" id="exampleInputEmail1" name={`items[${index}].unitPrice`} value={poFormik?.values?.items?.[index]?.unitPrice} onChange={(e) => {
                                if (/^\d*\.?\d*$/.test(e.target.value)) {
                                  poFormik.handleChange(e);
                                }
                              }} onBlur={poFormik.handleBlur} disabled={!poFormik?.values?.items?.[index]?.selected} />
                              {poFormik?.touched?.items?.[index]?.unitPrice && poFormik?.errors?.items?.[index]?.unitPrice ? (<div className='req-error'>{poFormik?.errors?.items?.[index]?.unitPrice}</div>) : null}
                            </div>
                          </td>
                          <td>{ele?.itemDetails?.tax}%</td>
                          <td>{formatPrice((Number(poFormik?.values?.items?.[index]?.unitPrice) * Number(poFormik?.values?.items?.[index]?.quantity)))}</td>
                          <td><i data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.setValues(ele?.itemDetails); itemFormik.setFieldValue('totalQuantity', poFormik?.values?.items?.[index]?.quantity); itemFormik.setFieldValue('unitPrice', poFormik?.values?.items?.[index]?.unitPrice) ; setSelectItemId(ele?.itemDetails?._id) }} className="bi bi-pencil-square point"></i></td>
                        </tr>)
                      })
                      }
                    </>}

                  </tbody>
                </table>
                <hr />
                <div className="form-details">
                  <label htmlFor="remarks" className="form-label">{langPreference?.remarks || "Remarks"}</label>
                  <textarea className="w-100 form-control" type="text" name={`remarks`} placeholder={langPreference?.write || "Enter note..."} disabled="" onChange={poFormik.handleChange} value={poFormik?.values?.remarks} />
                  {poFormik?.touched?.remarks && poFormik?.errors?.remarks ? (<div className='req-error'>{poFormik?.errors?.remarks}</div>) : null}
                </div>

                {poFormik?.values?.items > 0 && <div className="tm-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark mb-0">{langPreference?.total_amount || "Total amount"}</p>
                    <p className="light mb-0">{getTotalPoAmt(poFormik?.values?.items)}</p>
                  </div>
                </div>}
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="order-review">
                <div className="d-flex bb justify-content-between align-items-center">
                  <h6>{langPreference?.preview || "Preview"}</h6>

                </div>
                {poFormik.values.vendorId && <div className="org">
                  <div className="d-flex  justify-content-between align-items-center">
                    <h4 className="mb-3">{poFormik.values?.vendorDetails?.name}</h4>
                    {poFormik.values.vendorId && <div onClick={() => { editVendorHandler(poFormik.values.vendorDetails) }} className='cursor-pointer'><i className="bi bi-pencil-square"></i></div>}
                  </div>

                  {poDetails?.poNumber && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.po_number || "PO number"}</p>
                    <p className="light">{poDetails?.poNumber}</p>
                  </div>}

                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.po_date || "PO date"}</p>
                    <p className="light">{moment(poFormik.values?.createDate).format("DD/MM/YYYY")}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{poFormik.values?.vendorDetails?.type=="Vendor" ? (langPreference?.gst_number || "GST number") : (langPreference?.pan_number ||  "PAN number")}</p>
                    <p className="light">{poFormik.values?.vendorDetails?.type=="Vendor" ? poFormik.values?.vendorDetails?.gstNumber : poFormik.values?.vendorDetails?.panNumber}</p>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">GST number</p>
                    <p className="light">{poFormik.values?.vendorDetails?.gstNumber}</p>
                  </div> */}
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.po_due_date || "PO due date"}</p>
                    <p className="light">{poFormik.values?.dueDate && moment(poFormik.values?.dueDate).format("DD/MM/YYYY")}</p>
                  </div>
                  {poDetails?.status && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.status || "Status"}</p>
                    <p className="light">{poDetails?.status}</p>
                  </div>}
                  <div className="add-box">
                    <h5>{langPreference?.address || "Address"}:</h5>
                    {poFormik.values.vendorId && <p className="mb-0">{poFormik.values?.vendorDetails?.vendorAddressId?.address1}, {poFormik.values?.vendorDetails?.vendorAddressId?.address2}, {poFormik.values?.vendorDetails?.vendorAddressId?.city}, {poFormik.values?.vendorDetails?.vendorAddressId?.state}, {poFormik.values?.vendorDetails?.vendorAddressId?.pinCode}</p>}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6>{langPreference?.items || "Items"} ({langPreference?.count || "count"}: {poFormik?.values?.items?.filter(item => item?.selected)?.length})</h6>
                    {/* <i className="bi bi-pencil-square"></i> */}
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }} scope="col">{langPreference?.name || "NAME"}</th>
                        <th scope="col">{langPreference?.qty || "QTY"}</th>
                        <th scope="col">{langPreference?.rate || "RATE"}</th>
                        <th scope="col">{langPreference?.gst || "GST"}</th>
                        <th scope="col">{langPreference?.amout || "AMOUNT"}</th>
                        <th scope="col">{langPreference?.edit || "EDIT"}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {poFormik.values?.vendorId && <>
                        {poFormik?.values?.items?.filter(item => item?.selected)?.map((ele, index) => {
                          // console.log(ele)
                          return (<tr>
                            <td style={{ overflowWrap: "anywhere" }}>{ele?.itemDetails?.name}</td>
                            <td>
                              {ele?.quantity}
                            </td>
                            <td>
                              {ele?.unitPrice}
                            </td>
                            <td>{ele?.itemDetails?.tax}%</td>
                            <td>{formatPrice(ele?.quantity * ele?.unitPrice)}</td>
                            <td><i data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.setValues(ele?.itemDetails);setSelectItemId(ele?.itemDetails?._id) }} className="bi bi-pencil-square point"></i></td>
                          </tr>)
                        })
                        }
                      </>}
                    </tbody>
                  </table>
                  <div className="amount-box">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="dark">{langPreference?.amount ||"Amount"}</p>
                      <p className="light">{formatPrice(totalPrice(poFormik?.values?.items?.filter(item => item?.selected)))}</p>
                    </div>

                    {(Object.keys(taxItems).sort((a, b) => b - a)).map((item, index) => {

                      return (
                        taxItems[item]?.filter(i => i?.selected)?.length ? <div key={index}>
                          <div className="d-flex justify-content-between gap-2 ">
                            <p className="dark">{langPreference?.cgst || "CGST"} ({item / 2}% on {totalPrice(taxItems[item]?.filter(i => i?.selected))?.toFixed(2)}) </p>
                            <p className="light">{formatPrice((totalPrice(taxItems[item]?.filter(i => i?.selected)) * item / 2) / 100)}</p>
                          </div>
                          <div className="d-flex justify-content-between gap-2 ">
                            <p className="dark">{langPreference?.sgst || "SGST"} ({item / 2}% on {totalPrice(taxItems[item]?.filter(i => i?.selected))?.toFixed(2)}) </p>
                            <p className="light">{formatPrice((totalPrice(taxItems[item]?.filter(i => i?.selected)) * item / 2) / 100)}</p>
                          </div>
                        </div> : null
                      )
                    })}
                    {/* <div className="d-flex r-of justify-content-between align-items-center">
                                                <p className="dark mb-0">R/off</p>
                                                <p className="light mb-0">0.50</p>
                                            </div> */}
                    {/* <div className="d-flex total justify-content-between align-items-center">
                      <p>Total amount</p>
                      <p>{formatPrice(totalAmount(poFormik?.values?.items?.filter(item => item?.selected)))}</p>
                    </div> */}
                    <div className="d-flex total justify-content-between align-items-center">
                      <p>{langPreference?.round_off || "R/off"}</p>
                      <p>{getRoundOff(totalAmount(poFormik?.values?.items?.filter(item => item?.selected))).toFixed(2)}</p>
                    </div>
                    <div className="d-flex total justify-content-between align-items-center">
                      <p>{langPreference?.total_amount || "Total amount"}</p>
                      <p>{formatPrice(Math.round(totalAmount(poFormik?.values?.items?.filter(item => item?.selected))))}</p>
                    </div>
                  </div>
                </div>}
                {poFormik.values.remarks && <div className="add-box">
                  <h5>{langPreference?.remarks || "Remarks"}:</h5>
                   <p className="mb-0">{poFormik.values?.remarks}</p>
                   <hr />
                </div>}
                {poFormik?.values?.termsAndCondition?.title && <>
                  <h5 className='fw-bold mt-3'>
                    {langPreference?.terms_conditions || "Terms and conditions"} 
                  </h5>
                  <div className="">
                    <h6 className="fw-bold">{poFormik?.values?.termsAndCondition?.title} </h6>
                    <div>{poFormik?.values?.termsAndCondition?.description}</div>
                  </div>
                  
                </>}

               
              </div>
            </div>
          </div>
        </div>
      </div>

      {!reLoadVendor && <ItemsAddModal formik={itemFormik} modalId={addItemModalId} addLoader={addItemLoader} type="po" removeItem={removeItem} selectItem={selectItemId} listType={poFormik?.values?.type}/> }
      <VendorAddModal formik={VendorFormik} modalId={addVendorModalId} addLoader={addItemLoader} />
      <div className="col">
        <div className="btn btn-primary hidden" style={{ display: "none" }} data-bs-toggle="modal" id='vendorCreate' data-bs-target={`#${addVendorModalId}`} onClick={() => { itemFormik.resetForm() }} >Create Vendor</div>
      </div>
      <ConfirmationModal modalId={ItemDeleteModalId} handleConfirm={handleItemDelete} message={<>Are you sure you want to <br /> Delete ?</>} />
      {/* for po approval */}
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={()=>handleConfirmation(false)} message={langPreference?.submit_confirmation || <>Are you sure you want to <br /> Submit ?</>} />
       <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
        <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleApprovalModalClose}
        />

        {/* for po convert to purchase */}
        <button className='d-none' id="convertModalBtn" data-bs-toggle="modal" data-bs-target={`#${convertConfirmModal}`} />
      <ConfirmationModal modalId={convertConfirmModal} handleConfirm={() => handleConfirmation(true)} message={langPreference?.confirm_submit_convert_po || <>Are you sure you want to submit and <br /> convert to Purchase order?</>} />

    </>
  )
}

export default AddPurchaseOrder