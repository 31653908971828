import React, { useEffect, useState } from 'react'
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import Spinwheel from '../../components/common/Spinwheel';
import { challanApproveOrRejectApi, getChallanByIdApi,downloadChallanPdf, submitBackApproveChallanApi, sendChallanToVendorApi } from '../../utils/ApiEndpointFunctions';
import { S3_URL } from '../../utils/BaseUrl';
import ImageViewModal from '../../components/modal/ImageViewModal';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import moment from 'moment';
import Tooltip from 'react-tooltip-lite';
import StatusModal from '../../components/modal/StatusModal';

function ViewChallan() {
  const params = useParams();
  const { userDetails,langPreference } = useUserContext();
  const { id } = params;
  const viewImageModalId = "viewImageModalId";
  const [challanData, setChallanData] = useState();
  const navigate = useNavigate();
  const approveConfirmModal = "approveConfirmModal"
  const statusModalId = "statusModal"
  const declineModalId = "declineModalId"
  const rejectConfirmModal = "rejectConfirmModal";
  const [selectImage, setSelectImage] = useState("")
  const [loader, setLoader] = useState({
    approve: false,
    makeAcopy: false
  })  
  const [taxItems, setTaxItems] = useState({});
  const [confirmationMsg, setConfirmationMsg] = useState("")
  const [download,setDownload] = useState(false)
  const [sendingMail,setSendingMail] = useState(false)
  const location = useLocation();

  // let imageArray = ["truckFrontSide", "truckBackSide", "beforeUnload", "afterUnload", "challanFrontSide", "challanBackSide", "invoiceFrontSide", "invoiceBackSide", "signature"]
  let addressKey = ["address1", "address2", "city", "state", "pinCode"];

  const getFileName = (name) => {
    // console.log("langPreference");
    
    const fileNames = {
      "truckFrontSide": langPreference?.truck_front_side || "Truck Front Side",
      "truckBackSide": langPreference?.truck_back_side ||   "Truck Back Side",
      "beforeUnload": langPreference?.before_unload ||  "Before Unload Items",
      "afterUnload":langPreference?.after_unload ||   "After Unload Items",
      "challanFrontSide":langPreference?.challan_front_side ||   "Challan Front Side",
      "challanBackSide": langPreference?.challan_back_side ||  "Challan Back Side",
      "invoiceFrontSide": langPreference?.invoice_front_side ||  "Invoice Front Side",
      "invoiceBackSide": langPreference?.invoice_back_side ||  "Invoice Back Side",
      "signature": langPreference?.signature ||  "Signature"
    }

    return fileNames[name] || ""
  }

  const previewTaxItems = (items) => {
    let taxItem = {};
    items?.forEach(item => {
      if (taxItem[item?.itemDetails?.tax]?.length > 0) {
        taxItem[item?.itemDetails?.tax].push(item)
      } else {
        taxItem[item?.itemDetails?.tax] = [item];
      }
    })
    setTaxItems(taxItem)
  }


  const getChallanById = async (id) => {
    try{

      const res = await getChallanByIdApi(id)
      if (res.status === 200) {
        const challan = res.data?.data?.challan
        
        setChallanData(challan)
        previewTaxItems(challan?.challanItems)
      }
    }
    catch(err){
      console.log(err)
      toast.error((err.response && err.response.data && err.response.data.message) || "Something Went Wrong")
    }
  }

  const avargeTax = (items) => {
    if (items?.length > 0) {
      let tax = 0;
      items.forEach(item => {

        tax += Number(item?.itemDetails.tax)
      })
      return tax / items.length
    }
    return 0;
  }

  const totalPrice = (items) => {

    if (items?.length > 0) {
      let price = 0;
      items.forEach(item => {

        price += Number(item?.unitPrice) * Number(item?.quantity)
      })
      return price
    }
    return 0;
  }

  const approveChallanHandler = async () => {
    try {
      const res = await challanApproveOrRejectApi({ id: id, status:"approved" })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(approveConfirmModal).click()
        setConfirmationMsg(res?.data?.message || `Challan ${challanData?.challanNumber} is successfully approved.`)
        document.getElementById("statusModalBtn").click()
        getChallanById(id)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const submitBackApproveChallanHandler = async () => {
    try {
      const res = await submitBackApproveChallanApi({ id: id, status:"decline" })
      if (res.status === 200) {
        // toast.success(res.data?.message)
        document.getElementById(approveConfirmModal).click()
        setConfirmationMsg(res?.data?.message || `Challan ${challanData?.challanNumber} is successfully declined.`)
        document.getElementById("statusModalBtn").click()
        getChallanById(id)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const rejectChallanHandler = async () => {
    try {
      const res = await challanApproveOrRejectApi({ id: id, status: "rejected" })
      if (res.status === 200) {
        // toast.success(res.data?.message)
        document.getElementById(rejectConfirmModal).click()
        setConfirmationMsg(res?.data?.message || `Challan ${challanData?.challanNumber} is successfully rejected.`)
        document.getElementById("statusModalBtn").click()
        getChallanById(id)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const totalAmount = (items) => {
    if (items?.length > 0) {
      let amt = 0
      items.forEach(item => {

        amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
      })
      return amt
    }
  }

  const statusHandler = (status) => {
    
    switch (status) {
      case "submitted":
        return <div className='badge bg-success align-self-center'>submitted</div>
      case "rejected":
        return <div className='badge bg-danger align-self-center'>Rejected</div>
      default:
        return <div className='badge bg-primary align-self-center'>{status}</div>
    }
  }

  const addressFormatter = (vendor) => {
    let address = ""
    
    addressKey.forEach((ele,index) => {
      if (vendor?.[ele]) {
        address += vendor?.[ele] + ((addressKey.length - 1) === index ? "" : ", ");
      }
    });
    return address
  }

  const checkFileCondition = (fileKeys) => {
    const fileObject = challanData?.challanFiles?.[0]
    return fileKeys.some(key => fileObject?.[key]);
  }

  const renderImageSection = (heading,langPreference, fileKey) => {
    const challanFileObj = challanData?.challanFiles?.[0]
    return (
      checkFileCondition(fileKey) && <>
        {heading !== "Truck Photo" && <hr />}
        <div className='fw-bold'>{langPreference}</div>
        {fileKey.map((fileName, index) => (
          checkFileCondition([fileName]) && (<div key={index} className="d-flex justify-content-between align-items-center gap-2 form-details w-100">
            <div className="">{getFileName(fileName)}</div>
            <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} data-bs-toggle="modal" data-bs-target={`#${viewImageModalId}`} onClick={() => { setSelectImage(S3_URL + challanFileObj[fileName]) }}>
              <img src={S3_URL + challanFileObj[fileName]} alt="" className='img-fluid' />
            </div>
          </div>))
        )}
      </>)
  }

  useEffect(() => {
    if (id) {
      getChallanById(id)
    }
  }, [])

  const getDownloadChallan =async(id)=>{
    try {
      setDownload(true)
      const response = await downloadChallanPdf(id)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', challanData?.challanNumber ? `${challanData.challanNumber}.pdf` :'challan.pdf'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }

  const sendChallanToVendor = async () => {
    setSendingMail(true)
    try {
      const res = await sendChallanToVendorApi(id)
      if (res.status === 200) {
        toast.success(res.data?.message);
      }
    } catch (error) {
      if(error && error?.response?.status === 409 && error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
      }else{
        toast.error("something went wrong");
      }
    }
    setSendingMail(false)
  }

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              <div className="row d-flex justify-content-center">
                <div className="col-xxl-11 col-lg-10 col-md-12">
                  </div>
                  <div className='d-flex justify-content-between align-items-center px-4' >
                  <div className="back cursor-pointer" onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} ><i className="bi bi-chevron-left"></i>{langPreference?.back || "Back"}</div>
                    {/* {challanData?.status === "created" && userDetails?.roleId?.permission?.includes("challanWrite") && <div className="btn btn-light border-2 text-primary border-primary m-1" onClick={() => { navigate(`/challan/Edit/${id}`) }} >Submit for Approve</div>} */}
                    {/* {userDetails?.roleId?.permission.includes("voucherWrite") && <div><button className="create-order" onClick={() => navigate("/voucher/add", { state: { number: challanData?.challanNumber,name:challanData?.purchaseOrder?.vendor?.name, amount: challanData?.totalRemainingVoucherAmount ?? totalAmount(challanData?.challanItems) }})}>Create voucher</button></div>} */}
                   
                  </div>
                  <div className='row row-cols-1 row-cols-md-2 my-3'>
                  <div className='pr-2'>
                  <div className="card p-5">
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <h1 className='fs-2 text-capitalize'>
                          {challanData?.purchaseOrder?.vendor?.name ? challanData?.purchaseOrder.vendor.name : ""}
                        </h1>
                      </div>
                    </div>
                    <hr />
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.challan_status || "Challan status"}</div>
                      <div className='text-capitalize'>{statusHandler(challanData?.status)}</div>
                    </div>
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.challan_number || "Challan number"}</div>
                      <div>{challanData?.challanNumber}</div>
                    </div>
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.challan_created_date || "Challan created date"}</div>
                      <div>{challanData?.createDate && moment(challanData?.createDate).format("DD/MM/YYYY")}</div>
                    </div>
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.po_number || "PO Number"}</div>
                      <div><Link to={`/purchase/view/${challanData?.purchaseOrder?._id}`}>{challanData?.purchaseOrder?.poNumber}</Link></div>
                    </div>
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.po_date || "PO date"}</div>
                      <div>{moment(challanData?.purchaseOrder?.createDate).format("DD/MM/YYYY")}</div>
                    </div>
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.gst_number || "GST Number"}</div>
                      <div>{challanData?.purchaseOrder?.vendor?.gstNumber}</div>
                    </div>
                    {challanData?.purchaseOrder?.vendor?.type!="Vendor" && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.pan_number || "PAN Number"}</div>
                      <div>{challanData?.purchaseOrder?.vendor?.panNumber}</div>
                    </div>}
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.po_due_date || "Due date"}</div>
                      <div>{moment(challanData?.purchaseOrder?.dueDate).format("DD/MM/YYYY")}</div>
                    </div>
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.po_status || "PO Status"}</div>
                      <div>{challanData?.purchaseOrder?.status}</div>
                    </div>
                    <hr />
                    <div className='fs-4 fw-bold'>{langPreference?.address || "Address"}</div>
                    <div className='mt-3'>{addressFormatter(challanData?.purchaseOrder?.vendor?.vendorAddressId)}</div>
                    <hr />
                 
                    <div className='fs-4 fw-bold'>
                      {langPreference?.site_details || "Site details"}
                    </div>
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.site_name || "Site name"}</div>
                      <div>{challanData?.purchaseOrder?.site?.[0]?.name}</div>
                    </div>
                    <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference?.site_address || "Site address"}</div>
                      <div>{challanData?.purchaseOrder?.site?.[0]?.addressId?.address1}</div>
                    </div>
                    <hr />
                    <div className='mt-3'>
                      <div className='fs-4 fw-bold'>
                        {langPreference?.shipment_details || "Shipment details"}
                      </div>
                      <hr />
                      {challanData?.truckNumber && <div className='mt-3 row row-cols-2'>
                        <div className='fw-bold'>{langPreference?.truck_number || "Truck number"}</div>
                        <div>{challanData?.truckNumber}</div>
                      </div>}
                      {challanData?.taxInvoiceNumber && <div className='mt-3 row row-cols-2'>
                        <div className='fw-bold'>{langPreference?.tax_invoice_number || "TAX invoice number"}</div>
                        <div>{challanData?.taxInvoiceNumber}</div>
                      </div>}
                      {challanData?.taxInvoiceDate && <div className='mt-3 row row-cols-2'>
                        <div className='fw-bold'>{langPreference?.tax_invoice_date || "TAX invoice Date"}</div>
                        <div>{moment(challanData?.taxInvoiceDate).format("DD/MM/YYYY")}</div>
                      </div>}
                    </div>
                    <hr/>
                    {challanData?.createdBy?.firstName && <div className="row row-cols-2 mt-3">
                        <div className="fw-bold">{langPreference?.created_by || "Created By"}</div>
                        <div className='text-capitalize'>{challanData?.createdBy?.firstName || ""} {challanData?.createdBy?.lastName || ""}</div>
                      </div>}
                      {challanData?.createdAt && <div className="row row-cols-2 mt-3">
                        <div className="fw-bold">{langPreference?.created_on || "Created On"}</div>
                        <div className='text-capitalize'>{challanData?.createdAt && moment(challanData?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                      </div>}
                      {challanData?.status!=="created" && challanData?.submittedBy?.firstName && <div className="row row-cols-2 mt-3">
                        <div className="fw-bold">{langPreference?.submitted_by || "Submitted By"}</div>
                        <div className='text-capitalize'>{challanData?.submittedBy?.firstName || ""} {challanData?.submittedBy?.lastName || ""}</div>
                      </div>}
                      {challanData?.status!=="created" && challanData?.submittedOn && <div className="row row-cols-2 mt-3">
                        <div className="fw-bold">{langPreference?.submitted_on || "Submitted On"}</div>
                        <div className='text-capitalize'>{challanData?.submittedOn && moment(challanData?.submittedOn).format("DD/MM/YYYY HH:mm")}</div>
                      </div>}
                      {challanData?.approvedBy?.firstName && <div className="row row-cols-2 mt-3">
                        <div className="fw-bold">{langPreference?.approved_by || "Approved By"}</div>
                        <div className='text-capitalize'>{challanData?.approvedBy?.firstName || ""} {challanData?.approvedBy?.lastName || ""}</div>
                      </div>}
                      {challanData?.approvedOn && <div className="row row-cols-2 mt-3">
                        <div className="fw-bold">{langPreference?.approved_on || "Approved On"}</div>
                        <div className='text-capitalize'>{challanData?.approvedOn && moment(challanData?.approvedOn).format("DD/MM/YYYY HH:mm")}</div>
                      </div>}
                    <hr />
                    <h1 className='fs-4 fw-bold'>
                      {langPreference?.items || "Items"} ({challanData?.challanItems?.length || 0})
                    </h1>
                    <div className='table-responsive'>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{langPreference?.name || "Name"}</th>
                            {/* <th scope="col">HSN CODE</th> */}
                            <th scope="col">{langPreference?.qty || "QTY"}</th>
                            <th scope="col">{langPreference?.rate || "Rate"}</th>
                            <th scope="col">{langPreference?.gst || "GST"}</th>
                            <th scope="col">{langPreference?.amount || "Amount"}</th>
                            <th scope="col">{langPreference?.breakage || "Breakage"}</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">
                          {challanData?.challanItems?.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <th style={{ textTransform: 'none' }}>{userDetails?.language=='hi' ? (ele?.itemDetails?.localName || ele?.localName ) : (ele?.name || ele?.itemDetails?.name)}</th>
                                {/* <td>{ele?.hsnCode ? ele?.hsnCode : ele?.itemDetails?.hsnCode}</td> */}
                                <td>{ele?.quantity}/{ele?.unitType ? ele?.unitType : ele?.itemDetails?.unitType}</td>
                                <td>{ele?.unitPrice}</td>
                                <td>{ele?.tax ? ele?.tax : ele?.itemDetails?.tax}%</td>
                                <td className='text-nowrap'>{formatPrice(ele?.quantity * ele?.unitPrice)}</td>
                                <td>{(ele.breakage ?
                                  <div>
                                    <Tooltip
                                      content={(
                                        <div  >
                                          {ele.breakage}
                                        </div>
                                      )}
                                      direction="up"
                                      tagName="div"
                                      className="target d-inline"
                                      background='#333'
                                      tipContentClassName="text-light font-weight-bold"
                                      styles={{ cursor: "pointer" }}
                                    >
                                      Yes
                                    </Tooltip>
                                  </div> : "No")}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>


                    <div className='price-card p-2 px-3'>
                      <div className="d-flex justify-content-between gap-2 mt-3">
                        <div className="fw-bold">{langPreference?.amount || "Amount"}</div>
                        <div>{formatPrice(totalPrice(challanData?.challanItems))}</div>
                      </div>
                      {(Object.keys(taxItems).sort((a, b) => b - a)).map((item, index) => {

                        return (
                          <div key={index}>
                            <div className="d-flex justify-content-between gap-2 mt-3">
                              <div className="fw-bold">{langPreference?.cgst || "CGST"} ({item / 2}%  {langPreference?.on || "on"} {totalPrice(taxItems[item])?.toFixed(2)}) </div>
                              <div>{formatPrice((totalPrice(taxItems[item]) * item / 2) / 100)}</div>
                            </div>
                            <div className="d-flex justify-content-between gap-2 mt-3">
                              <div className="fw-bold">{langPreference?.sgst || "SGST"} ({item / 2}% {langPreference?.on || "on"} {totalPrice(taxItems[item])?.toFixed(2)}) </div>
                              <div>{formatPrice((totalPrice(taxItems[item]) * item / 2) / 100)}</div>
                            </div>
                          </div>
                        )
                      })}
                      <hr />
                        <div className="d-flex total justify-content-between align-items-center">
                          <p>{langPreference?.round_off || "R/off"}</p>
                          <p>{getRoundOff(totalAmount(challanData?.challanItems)).toFixed(2)}</p>
                        </div>
                      <div className="d-flex justify-content-between gap-2 fw-bold">
                        <div className="fw-bold">{langPreference?.total_amount || "Total Amount"}</div>
                        <div>{formatPrice(Math.round(totalAmount(challanData?.challanItems)))}</div>
                      </div>
                    </div>
                      <hr />
                      <div className='fs-4 fw-bold'>{langPreference?.remarks || "Remarks"}</div>
                      <div className='mt-3'>{challanData?.remarks}</div>
                      <hr />
                   <div className="d-flex align-items-center justify-contend-start gap-2 flex-wrap">
                      {challanData?.status === "submitted" && userDetails?.roleId?.permission.includes("challanApprove") &&  <div className="d-flex justify-content-between gap-2">
                        <div className="create-order cursor-pointer" data-bs-toggle="modal" data-bs-target={`#${approveConfirmModal}`}>{langPreference?.approve || "Approve"}</div>
                      </div>}
                      {challanData?.status === "approved" && userDetails?.roleId?.permission.includes("submitBackApproveChallan") &&  <div className="d-flex justify-content-between gap-2">
                        <div className="create-order cursor-pointer" data-bs-toggle="modal" data-bs-target={`#${declineModalId}`}>{langPreference?.reject || "Decline"}</div>
                      </div>}
                      
{/* 
                      {challanData?.status === "submitted" && userDetails?.roleId?.permission.includes("challanReject") &&  <div className="d-flex justify-content-between gap-2 mt-3">
                        <div className="btn btn-secondary" data-bs-toggle="modal" data-bs-target={`#${rejectConfirmModal}`}>Reject</div>
                      </div>} */}
                      {challanData?.status !== "approved" && ((challanData?.status === "created" && userDetails?.roleId?.permission?.includes("challanWrite")) || userDetails?.roleId?.permission?.includes("challanUpdate")) && 
                        <div className="d-flex justify-content-between gap-2">
                      <div className="create-order cursor-pointer" onClick={() => { navigate(`/challan/Edit/${id}`) }} >{langPreference?.edit || "Edit"}</div></div>}
                      {userDetails?.roleId?.permission.includes("voucherWrite") && challanData?.status?.toLowerCase() !== "created" && <div><button className="create-order" onClick={() => navigate("/voucher/add", { state: { number: challanData?.challanNumber,name:challanData?.purchaseOrder?.vendor?.name, amount: challanData?.totalRemainingVoucherAmount ?? totalAmount(challanData?.challanItems) }})}>{langPreference?.create_voucher || "Create voucher"}</button></div>}

                    {/* {userDetails?.roleId?.permission.includes("voucherWrite") && 
                    <div className="d-flex justify-content-between gap-2 mt-3">
                      <button className="create-order" onClick={() => navigate("/voucher/add", { state: { number: challanData?.challanNumber,name:challanData?.purchaseOrder?.vendor?.name, amount: challanData?.totalRemainingVoucherAmount ?? totalAmount(challanData?.challanItems) }})}>Create voucher</button>
                    </div>} */}

                        <div className="d-flex justify-contend-start gap-2">
                          {challanData?.status?.toLowerCase() === "approved" && <div className="d-flex justify-content-between gap-2">
                            <button className="btn create-order text-white" disabled={download} onClick={() => !download && id && getDownloadChallan(id)}>{langPreference?.download || "Download"}</button>
                          </div>}
                          {challanData?.status?.toLowerCase() === "approved" && <div className="d-flex justify-content-between gap-2">
                            <button className="btn create-order text-white" disabled={sendingMail} onClick={() => !sendingMail && id && sendChallanToVendor(id)}>{challanData?.purchaseOrder?.vendor?.type=="Vendor" ? ( langPreference?.send_to_vendor || "Send to Vendor") :(langPreference?.send_to_subcontractor || "Send to Subcontractor")}</button>
                          </div>}
                        </div>
                   </div>
                  </div>
                    </div>
                  <div className='pl-2'>
                  {/* <div className='card p-5'> */}
                      {/* <div className='fs-2 fw-bold'>
                        Photos
                      </div>
                      <div className="row row-cols-1 row-cols-md-6 mt-3">

                        {imageArray.map((ele, index) => {
                          if (challanData?.challanFiles?.[0]?.[ele]) return <div className='p-1'>
                            <div key={index} className='d-flex justify-content-center align-items-center border cursor-pointer' data-bs-toggle="modal" data-bs-target={`#${viewImageModalId}`} style={{ height: "80px" }} onClick={() => { setSelectImage(S3_URL + challanData?.challanFiles?.[0]?.[ele]) }} >
                              <img src={S3_URL + challanData?.challanFiles?.[0]?.[ele]} alt="" className='img-fluid h-100 w-100' style={{ objectFit: 'contain' }} /></div>
                            <div className='text-center'>
                              {getFileName(ele)}
                            </div>
                          </div>
                          return null;
                        })}

                        {
                          challanData?.challanFiles[0]?.otherFiles?.map((ele, index) => {

                            if (ele.image) return <div className='p-1'>
                              <div key={"asd" + index} className='d-flex justify-content-center align-items-center border m-1 cursor-pointer' data-bs-toggle="modal" data-bs-target={`#${viewImageModalId}`} style={{ height: "80px" }} onClick={() => { setSelectImage(S3_URL + ele.image) }} >
                                <img src={S3_URL + ele.image} alt="" className='img-fluid w-100 h-100' style={{ objectFit: 'contain' }} /></div>
                              <div className='text-center'>
                                {ele.description || ""}
                              </div>
                            </div>
                            return null;
                          })
                        }

                        
                      </div> */}


                      {/* ************************************************************************* */}

                      <div className="card p-5">
                        <div className="d-flex justify-content-between mb-1">
                          <div className="">
                            <h1 className='fs-4 fw-bold text-capitalize'>
                            {langPreference?.photos || "Photos"}
                            </h1>
                          </div>
                        </div>
                        <hr />
                        {challanData?.challanFiles?.length==0 && <p className='fs-4 text-center'>{langPreference?.no_photo_uploaded || "No Photo uploaded"}</p> }
                        {renderImageSection('Truck Photo',langPreference?.truck_photo || 'Truck Photo', ["truckFrontSide", "truckBackSide", "beforeUnload", "afterUnload"])}
                        {renderImageSection('Vendor Challan Photo',langPreference?.vendor_challan_photo || 'Vendor Challan Photo', ["challanFrontSide", "challanBackSide"])}
                        {renderImageSection('Tax Invoice Photo',langPreference?.tax_invoice_photo || 'Tax Invoice Photo', ["invoiceFrontSide", "invoiceBackSide"])}
                        {Boolean(challanData?.challanFiles?.[0]?.otherFiles.length) && <>
                          <hr />
                          <div className='fw-bold'>{langPreference?.other_photo || "Other Photos"}</div>
                          {challanData?.challanFiles?.[0]?.otherFiles.map((file, index) => (
                            (file?.image) && (<div key={index} className="row row-cols-2 align-items-center gap-2 form-details w-100">
                              <div className="">{file?.description}</div>
                              <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} data-bs-toggle="modal" data-bs-target={`#${viewImageModalId}`} onClick={() => { setSelectImage(S3_URL + file?.image) }}>
                                <img src={S3_URL + file.image} alt="" className='img-fluid' />
                              </div>
                            </div>))
                          )}
                        </>}
                        {renderImageSection('Delivery Person Signature',langPreference?.delivery_person_signature || 'Delivery Person Signature', ["signature"])}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <ConfirmationModal modalId={approveConfirmModal} handleConfirm={approveChallanHandler} message={langPreference?.approve_confirmation ||  <>Are you sure you want to <br /> Approve ?</>} />
      <ConfirmationModal modalId={rejectConfirmModal} handleConfirm={rejectChallanHandler} message={langPreference?.confirm_reject || <>Are you sure you want to <br /> Reject ?</>} />
      <ConfirmationModal modalId={declineModalId} handleConfirm={submitBackApproveChallanHandler} message={langPreference?.confirm_decline || <>Are you sure you want to <br /> Decline ?</>} />
      <ImageViewModal imgUrl={selectImage} modalId={viewImageModalId} />
      <ImageViewModal imgUrl={selectImage} modalId={viewImageModalId} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={() => navigate("/challan")}
      />
    </>
  )

}

export default ViewChallan