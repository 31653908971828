
export const  getToken = () => localStorage.getItem("token")

export const  getLanguage = () => localStorage.getItem("language")

export const  setLanguage = (lang="en") => localStorage.setItem("language",lang)

export const removeToken = () => localStorage.removeItem("token")
export const isAuth = () => Boolean(getToken())

export const capitalizeWords=(str)=> {
    if(!str) return
    const words = str?.split?.(' ');
  
    const capitalizedWords = words?.map(word => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word; 
      }
    });
  
    return capitalizedWords?.join(' ');
  }

  export const openImageInNewTab = (image) => {
    const imageUrl = `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${image}`
    window.open(imageUrl, '_blank');
  };

  export const formatPrice = (amount) =>{
    let newAmount = amount || 0
    return "₹ " + addCommas(newAmount.toFixed(2))
  }

function addCommas(number) {
  let isNegative = number < 0;
  let numberStr = String(Math.abs(number)); // Convert to positive for processing
  let parts = numberStr.split('.');
  let integerPart = parts[0];
  let result = '';
  for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
    if (count !== 0 && count % 3 === 0) {
      result = ',' + result;
    }
    result = integerPart[i] + result;
  }
  if (parts.length > 1) {
    result += '.' + parts[1];
  }
  return isNegative ? '-' + result : result;
}

export const convertByteToMB = (byte) => {
  if(!byte) return 0;
  return byte / (1024 * 1024)
}

export const acceptAlphaAndNumOnly = /^[a-zA-Z0-9]+$/;

export const getFileOriginalName = (filename) => {
 return filename?.split('_').slice(1)?.join('');
}

export const getRoundOff =(value)=>{
  if(isNaN(value)){
    return 0
  }else{
    return Number.parseFloat(Number(Math.round(value)-value).toFixed(2))
  }
}

export const validatePONumber = /PO-\d+/
export const validateChallanNumber = /CH-\d+/
export const validateVoucherNumber = /VR-\d+/
export const validatePOOrChallanNumber = /(PO|CH)-\d+/
export const validatePOChallanOrVoucherNumber = /(PO|CH|VR)-\d+/


export const isImageFile =(fileName)=>{
    const nameSplit = fileName?.split(".")
    const extension = nameSplit?.[nameSplit?.length-1]
    const imageExtension = ["jpeg","jpg","png"]
    return imageExtension.includes(extension) 
}

export const clearCacheData = () => {
  window?.caches?.keys()?.then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  });
};

export const getCommonCanva = async(sigCanvas)=>{
  try {
    const originalCanvas = sigCanvas.current.getTrimmedCanvas();

    // Fixed dimensions for the final signature image
    const FIXED_WIDTH = 500;  // Required width
    const FIXED_HEIGHT = 500; // Required height

    // Create a new canvas with fixed dimensions
    const finalCanvas = document.createElement("canvas");
    const ctx = finalCanvas.getContext("2d");

    finalCanvas.width = FIXED_WIDTH;
    finalCanvas.height = FIXED_HEIGHT;

    // Fill the canvas with white background
    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, FIXED_WIDTH, FIXED_HEIGHT);

    // Calculate the position to center the signature
    const xOffset = (FIXED_WIDTH - originalCanvas.width) / 2;
    const yOffset = (FIXED_HEIGHT - originalCanvas.height) / 2;

    // Draw the trimmed signature onto the new canvas without scaling
    ctx.drawImage(originalCanvas, xOffset, yOffset);
    
    const blob = await new Promise(resolve => finalCanvas.toBlob(resolve));
    return {status:1, blob}
  } catch (error) {
    return {status:0,}
  }
}